import { defaultQueryParamSerializer } from 'openapi-fetch';

// currently we need to override the default query serializer to handle the "Filter | filter" query parameter correctly since it's not following the spec
export function withFilterQuerySerializer(q: unknown) {
  const search: Array<string> = [];

  if (q && typeof q === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of Object.entries(q)) {
      let value;
      if (k.toLowerCase() === 'filter' && Array.isArray(v)) {
        if (!v.length) {
          value = undefined;
        } else {
          const nextValue: Array<string> = [];
          v.forEach((item, index) => {
            Object.entries(item).forEach((prop) => {
              if (prop[1] !== undefined && prop[1] !== null) {
                const next = defaultQueryParamSerializer([`${k}[${index}].${prop[0]}`], prop[1]);
                if (next !== undefined) {
                  nextValue.push(next);
                }
              }
            });
          });

          value = nextValue.join(`&`);
        }
      } else {
        value = defaultQueryParamSerializer([k], v);
      }
      if (value) {
        search.push(value);
      }
    }
  }
  return search.join('&');
}
