import { Button } from '@aph/ui/components/button/button';
import type { IJumpLinkComponentFields } from '~/contentful/types';

type JumpHyperLinkProps = Pick<IJumpLinkComponentFields, 'identifier'>;

export const JumpHyperLink: React.FC<{ children?: React.ReactNode } & JumpHyperLinkProps> = ({
  identifier,
  children,
}) => {
  const onClick = () => {
    const element = document.getElementById(identifier);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Button className="w-full" data-pw="jump-hyperlink" onClick={onClick} variant="tertiary">
      {children}
    </Button>
  );
};
