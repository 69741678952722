import type { BalanceV4 } from '~/services/generated/CustomerClient';
import {
  GroupedLoyaltyAccountBalanceTypeV4,
  GroupedLoyaltyAccountBalanceV4,
} from '~/services/generated/CustomerClient';

export const getGroupedBalancesByLoyaltyAccountBalanceType = (
  type: GroupedLoyaltyAccountBalanceTypeV4,
  balance: BalanceV4 | null,
) => {
  if (balance && balance.groupedBalances) {
    const resultGroup = balance.groupedBalances.find(
      (group) => group.groupedLoyaltyAccountBalanceType === type,
    );
    return resultGroup;
  }
  return null;
};

export const getStammisGroup = (balance: BalanceV4 | null) => {
  const noStammisGroupTemplate = new GroupedLoyaltyAccountBalanceV4({
    voucherValue: 0,
    pointValue: 0,
  });
  const stammisGroup = getGroupedBalancesByLoyaltyAccountBalanceType(
    GroupedLoyaltyAccountBalanceTypeV4.Stammis,
    balance,
  );
  return stammisGroup || noStammisGroupTemplate;
};

export const getPartnerGroup = (balance: BalanceV4 | null) => {
  const noPartnerGroupTemplate = new GroupedLoyaltyAccountBalanceV4({
    voucherValue: 0,
    pointValue: 0,
  });
  const partnerGroup = getGroupedBalancesByLoyaltyAccountBalanceType(
    GroupedLoyaltyAccountBalanceTypeV4.Other,
    balance,
  );
  return partnerGroup || noPartnerGroupTemplate;
};

export const getPartnerDetailedBalance = (balance: BalanceV4 | null) =>
  getPartnerGroup(balance).detailedBalances || [];

export const getStammisDetailedBalance = (balance: BalanceV4 | null) =>
  getStammisGroup(balance).detailedBalances || [];
