import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import type { BalanceV4 } from '~/services/generated/CustomerClient';
import { type BonusBalanceState, initialState } from './bonus-balance-state';
import { apiGetBonusBalance } from './bonus-balance.api';

interface BonusBalanceStore {
  bonusBalance: BonusBalanceState;
  actions: {
    getBonusBalance: () => void;
  };
}

export const useBonusBalanceStore = create<BonusBalanceStore>()(
  devtools<BonusBalanceStore>(
    (set) => ({
      bonusBalance: initialState,
      actions: {
        getBonusBalance: async () => {
          const correlationId = uuidv4();
          set(
            {
              bonusBalance: {
                ...initialState,
                loading: true,
                error: false,
                errorMessage: null,
                updated: Date.now(),
              },
            },
            false,
            'Fetching bonusBalance',
          );

          try {
            const response: BalanceV4 = await apiGetBonusBalance(correlationId);
            logger.info(
              `getBonusBalanceSaga, Correlation ID: ${correlationId} was fetched successfully`,
            );
            set(
              {
                bonusBalance: {
                  data: response || initialState.data,
                  loading: false,
                  error: false,
                  errorMessage: null,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching bonusBalance success',
            );
          } catch (event) {
            const { userMessage, status, httpStatusCode } = event as {
              userMessage: string;
              status: string;
              httpStatusCode: number;
            };
            const errorMessage = `${
              userMessage || 'Något gick fel! Tyvärr kan inte denna del visas just nu'
            }`;
            logger.error(
              `getBonusBalanceSaga, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${errorMessage} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );
            set(
              {
                bonusBalance: {
                  ...initialState,
                  loading: false,
                  error: true,
                  errorMessage,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching bonusBalance failed',
            );
          }
        },
      },
    }),
    {
      name: 'UseBonusBalance',
      anonymousActionType: 'UseBonusBalance',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useBonusBalanceActions = () => useBonusBalanceStore((state) => state.actions);
