import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getLoginRedirectUrl } from '@aph/utilities/login/login.utils';
import { useUserActions, useUserStore } from '~/model/user/user.store';

export const usePrescriptionProfile = () => {
  const router = useRouter();

  const { user } = useUserStore();
  const { fetch: fetchUser } = useUserActions();

  useEffect(() => {
    if (user === undefined) {
      fetchUser();
    }
  }, [fetchUser, user]);

  const isAuthenticated = user !== undefined;

  const loginHref = getLoginRedirectUrl('prescription', '/mina-recept/receptlistan');

  return useMemo(
    () => ({
      onClickButton: () =>
        isAuthenticated ? router.push('/mina-recept/receptlistan') : router.push(loginHref),
    }),
    [isAuthenticated, router, loginHref],
  );
};
