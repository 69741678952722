import { Box, Stack } from '@mui/material';
import { sendEvent } from '@aph/components/gtm/useGtm';
import type { FooterLinkListHeaderType, FooterLinkType } from '../links/links-data';
import { DesktopLinks } from './dekstop-links';
import { MobileLinks } from './mobile-links';

export interface LinksProps {
  links: FooterLinkType[];
  header: FooterLinkListHeaderType;
}

interface FooterLinksProps {
  linksData: LinksProps;
}

export const FooterLinks = ({ linksData }: FooterLinksProps) => {
  const { header, links } = linksData;

  const footerLinkClickEvent = ({ link }: { link: FooterLinkType }) => {
    const event = { event: 'footer_navigation', click_text: link.title };
    sendEvent(event);
  };

  return (
    <Stack>
      <Box display={{ sm: 'none', xs: 'block' }}>
        <MobileLinks footerLinkClickEvent={footerLinkClickEvent} header={header} links={links} />
      </Box>
      <Box display={{ sm: 'block', xs: 'none' }}>
        <DesktopLinks footerLinkClickEvent={footerLinkClickEvent} header={header} links={links} />
      </Box>
    </Stack>
  );
};
