import resolveResponse from 'contentful-resolve-response';
import { BaseApiClient } from '@aph/utilities/BaseApiClient';
import type { RedirectData } from '@aph/utilities/errors/types';
import { appendLeadingSlash, appendTrailingSlash, compareSlugs } from '@aph/utilities/slug';
import { logger } from '~/logging';
import type { components, paths } from '../generated/api.schema';
import type { CONTENT_TYPE, IBrandPage, ICampaignPage, ICategoryPage, IEntry } from '../types';

const getPreviewToken = (forcePreview?: boolean) => {
  if (forcePreview || process.env.NODE_ENV === 'development') {
    return process.env.CONTENTFUL_PREVIEW_TOKEN;
  }
  return undefined;
};

export type NavigationItem = components['schemas']['NavigationItem'];

type GetEntityParams = {
  contentType: CONTENT_TYPE;
  slug: string;
  preview?: boolean;
};

type PageTypes = Extract<IEntry, ICategoryPage | IBrandPage | ICampaignPage>;

export class ContentApiClient extends BaseApiClient<paths> {
  /**
   * Retrieves an entity by its slug (fields.slug).
   * You need to supply the content type and the slug.
   *
   * An optional preview flag can be set to true to retrieve the draft version of the entity.
   */
  public async getEntityBySlug<T extends IEntry>(params: GetEntityParams) {
    const { data, error } = await this.client.GET(
      '/content/v{version}/content/environments/{environmentId}',
      {
        params: {
          path: {
            version: '1',
            environmentId: process.env.CONTENTFUL_ENVIRONMENT ?? 'master',
          },
          query: {
            limit: 1,
            include: 10,
            'fields.slug': params.slug,
            content_type: params.contentType,
            preview_token: getPreviewToken(params.preview),
          },
        },
      },
    );

    if (error) {
      // TODO: throw better error,maybe we can move to base api with message
      throw error;
    }

    return resolveResponse(data, { removeUnresolved: true, itemEntryPoints: ['fields'] })[0] as T;
  }

  /**
   * Retrieves a page by its slug (fields.slug). This method will also handle redirects which is applicable for some page types.
   * You need to supply the content type and the slug.
   *
   * An optional preview flag can be set to true to retrieve the draft version of the page.
   */
  public async getPage<T extends PageTypes>(params: GetEntityParams) {
    return this.getEntityBySlug<T>(params).then((data) => {
      if (!compareSlugs(data.fields.slug, params.slug)) {
        logger.debug(`Redirect to ${data.fields.slug}`);
        return {
          isRedirect: true,
          location: appendTrailingSlash(appendLeadingSlash(data.fields.slug)),
        } as RedirectData;
      }
      return data as T;
    });
  }

  /**
   * Retrieves a navigation tree for a given content type.
   * You can supply a slug to get the navigation tree for a specific entity.
   * You can also supply a levels parameter to get a specific depth of the tree.
   */
  public async getNavigation(
    params: paths['/content/v{version}/navigations']['get']['parameters']['query'],
  ) {
    const { data, error, response } = await this.client.GET('/content/v{version}/navigations', {
      params: {
        path: {
          version: '1',
        },
        query: {
          type: params.type,
          slug: params.slug,
          levels: params.levels,
        },
      },
    });

    if (error) {
      logger.error({ ...error, url: response.url }, `getNavigation: ${error.title}.`);
      return [];
    }

    return data;
  }
}
