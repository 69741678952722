import { useMemo } from 'react';
import { getStammisGroup } from '@aph/components/club-member/my-bonus/bonus/bonus.utils';
import { sendProfileMenuEvent } from '@aph/components/gtm/events/profile-menu-gtm';
import { useGetPersonalOffersQuery } from '@aph/hooks/use-get-personal-offers-query';
import type { ButtonProps } from '@aph/ui/components/button/button';
import { formatFullName } from '@aph/utilities/formatFullName';
import { getLogOutRedirectUrl } from '@aph/utilities/log-out/log-out.utils';
import { authRedirectConfig } from '~/contexts/auth/auth.redirect.config';
import { useAmIMemberStore } from '~/model/member/am-i-member/use-am-i-member-store';
import { useUserStore } from '~/model/user/user.store';
import { useProfile } from '../header-profile/header-profile.container';
import { ProfileMenuContent } from './profile-menu-content.component';
import { ProfileMenuComponent } from './profile-menu.component';
import { sumAphValidVouchers } from './profile-menu.utils';
import { useGetBonusVouchersQuery } from './use-get-bonus-vouchers-query';
import { useProfileMenuStore } from './use-profile-menu-store';

type ProfileMenuProps = {
  buttonSize?: ButtonProps['size'];
};

export const ProfileMenu = ({ buttonSize = 'small' }: ProfileMenuProps) => {
  const { login, isAuthenticated, bonusBalance } = useProfile();
  const stammisGroup = getStammisGroup(bonusBalance.data);
  const { hasNoSessionRedirect } = authRedirectConfig();
  const { amIMember } = useAmIMemberStore();
  const isMember = amIMember.data && amIMember.data.isMember;
  const { user } = useUserStore();
  const fullName = formatFullName({ firstName: user?.firstName, lastName: user?.lastName });

  const { data: personalOffers } = useGetPersonalOffersQuery({ enabled: isMember ?? false });
  const { data: bonusVouchers } = useGetBonusVouchersQuery({ enabled: isMember ?? false });

  const { shouldShowPersonalOffersBadge, setShouldShowPersonalOffersBadge } = useProfileMenuStore();

  const personalOffersCount = personalOffers?.combinations?.length ?? 0;

  const totalAphCheckAmount = useMemo(() => {
    return sumAphValidVouchers(bonusVouchers?.adhocVouchers, bonusVouchers?.monthVouchers);
  }, [bonusVouchers?.adhocVouchers, bonusVouchers?.monthVouchers]);

  const handleMenuItemClick = (title: string) => {
    sendProfileMenuEvent(title);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) return;
    setShouldShowPersonalOffersBadge(false);
  };

  return (
    <ProfileMenuComponent
      isAuthenticated={isAuthenticated}
      onLogin={login}
      onOpenChange={handleOpenChange}
      buttonSize={buttonSize}
      shouldShowPersonalOffersBadge={shouldShowPersonalOffersBadge && personalOffersCount > 0}
    >
      <ProfileMenuContent
        fullName={fullName}
        isMember={isMember}
        logoutUrl={getLogOutRedirectUrl(hasNoSessionRedirect)}
        onMenuItemClick={handleMenuItemClick}
        stammisGroup={stammisGroup}
        totalAphCheckAmount={totalAphCheckAmount}
        personalOffersCount={personalOffersCount}
      />
    </ProfileMenuComponent>
  );
};
