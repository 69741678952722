import { forwardRef } from 'react';
import Link from 'next/link';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { GroupedLoyaltyAccountBalanceV4 } from '~/services/generated/CustomerClient';

type ProfileMenuContentProps = {
  fullName: string;
  isMember?: boolean | null;
  logoutUrl: string;
  onMenuItemClick: (title: string) => void;
  stammisGroup: GroupedLoyaltyAccountBalanceV4;
  totalAphCheckAmount: number;
  personalOffersCount?: number;
};

export const ProfileMenuContent = forwardRef<HTMLDivElement, ProfileMenuContentProps>(
  (
    {
      fullName,
      isMember,
      logoutUrl,
      onMenuItemClick,
      stammisGroup,
      totalAphCheckAmount,
      personalOffersCount,
    },
    ref,
  ) => {
    const intl = useIntl();
    const loggedInAs = `${intl.messages['COMMON.PROFILE.MENU.LOGGED.IN.AS'].toString()} ${fullName}`;
    const shouldShowPersonalOffers = !!personalOffersCount;

    return (
      <DropdownMenu.Content
        className="bg-elevated rounded-base z-[1100] w-[250px] pt-1 shadow-[0px_2px_8px_rgba(0,0,0,0.32)]"
        sideOffset={2}
        align="end"
        ref={ref}
      >
        <DropdownMenu.Label className="px-2 py-1">
          <Typography typography="footnote" color="text-subtle" aria-hidden>
            <FormattedMessage id="COMMON.PROFILE.MENU.LOGGED.IN.AS" />
          </Typography>
          <Typography typography="subHeading" className="w-full truncate" aria-hidden>
            {fullName}
          </Typography>
          <p className="sr-only">{loggedInAs}</p>
        </DropdownMenu.Label>
        <DropdownMenu.Separator asChild>
          <hr className="border-default" aria-hidden />
        </DropdownMenu.Separator>
        {isMember && (
          <div className="bg-success m-1 rounded-xl p-2">
            <div className="flex flex-col gap-0.5">
              <Typography className="font-bold" aria-hidden>
                <FormattedMessage id="COMMON.PROFILE.MENU.MY.BONUS.TEXT" />
              </Typography>
              <Typography
                typography="subHeading"
                aria-hidden
              >{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VALUE.LABEL'].toString()} ${stammisGroup.pointValue}p`}</Typography>
              <p className="sr-only">{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VALUE.LABEL'].toString()} ${stammisGroup.pointValue} ${intl.messages['COMMON.PROFILE.MENU.POINTS']}`}</p>
              <Typography
                typography="subHeading"
                aria-hidden
              >{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VOUCHERS.LABEL'].toString()} ${totalAphCheckAmount}:-`}</Typography>
              <p className="sr-only">{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VOUCHERS.LABEL'].toString()} ${totalAphCheckAmount}:-`}</p>
            </div>
          </div>
        )}
        {isMember ? (
          <DropdownMenuItem>
            <Link
              className="flex gap-1"
              href="/klubb-hjartat/klubberbjudanden"
              onClick={() => {
                onMenuItemClick(intl.messages['COMMON.PROFILE.MENU.CLUB.OFFERS.TEXT'].toString());
              }}
            >
              <FormattedMessage id="COMMON.PROFILE.MENU.CLUB.OFFERS.TEXT" />
              {shouldShowPersonalOffers && (
                <span className="bg-notice text-notice flex aspect-square h-full items-center justify-center rounded-full text-xs">
                  {personalOffersCount}
                </span>
              )}
            </Link>
          </DropdownMenuItem>
        ) : (
          <DropdownMenuItem>
            <Link
              href="/klubb-hjartat/bli-medlem"
              onClick={() => {
                onMenuItemClick(intl.messages['COMMON.PROFILE.MENU.BECOME.MEMBER.TEXT'].toString());
              }}
            >
              <FormattedMessage id="COMMON.PROFILE.MENU.BECOME.MEMBER.TEXT" />
            </Link>
          </DropdownMenuItem>
        )}
        {isMember && (
          <DropdownMenuItem>
            <Link
              href="/klubb-hjartat/start"
              onClick={() => {
                onMenuItemClick(intl.messages['COMMON.PROFILE.MENU.MY.PAGES.TEXT'].toString());
              }}
            >
              <FormattedMessage id="COMMON.PROFILE.MENU.MY.PAGES.TEXT" />
            </Link>
          </DropdownMenuItem>
        )}
        {isMember && (
          <DropdownMenuItem>
            <Link
              href="/klubb-hjartat/orderhistorik"
              onClick={() => {
                onMenuItemClick(intl.messages['COMMON.PROFILE.MENU.ORDERS.TEXT'].toString());
              }}
            >
              <FormattedMessage id="COMMON.PROFILE.MENU.ORDERS.TEXT" />
            </Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <Link
            href="/mina-recept/receptlistan"
            onClick={() => {
              onMenuItemClick(intl.messages['COMMON.PROFILE.MENU.RECIPES.TEXT'].toString());
            }}
          >
            <FormattedMessage id="COMMON.PROFILE.MENU.RECIPES.TEXT" />
          </Link>
        </DropdownMenuItem>
        <DropdownMenu.Separator asChild>
          <hr className="border-default" aria-hidden />
        </DropdownMenu.Separator>
        <DropdownMenuItem className="text-action font-bold">
          <Link href={logoutUrl}>
            <FormattedMessage id="COMMON.PROFILE.MENU.LOGOUT.TEXT" />
          </Link>
        </DropdownMenuItem>
        <DropdownMenu.Arrow className="fill-inverse" aria-hidden />
      </DropdownMenu.Content>
    );
  },
);

const DropdownMenuItem = ({
  children,
  className,
  ...props
}: DropdownMenu.DropdownMenuItemProps) => (
  <DropdownMenu.Item
    className={cn(
      'outline-action hover:bg-default flex h-5 w-full items-center px-2 py-1 no-underline hover:cursor-pointer',
      className,
    )}
    asChild
    {...props}
    onPointerLeave={(event) => event.preventDefault()}
    onPointerMove={(event) => event.preventDefault()}
  >
    {children}
  </DropdownMenu.Item>
);
