import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type ProfileMenuStore = {
  shouldShowPersonalOffersBadge: boolean;
  setShouldShowPersonalOffersBadge: (shouldShowPersonalOffersBadge: boolean) => void;
};

export const useProfileMenuStore = create<ProfileMenuStore>()(
  devtools(
    persist(
      (set, get) => ({
        shouldShowPersonalOffersBadge: get()?.shouldShowPersonalOffersBadge ?? true,
        setShouldShowPersonalOffersBadge: (shouldShowPersonalOffersBadge: boolean) =>
          set({ shouldShowPersonalOffersBadge }),
      }),
      {
        name: 'profile-menu-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
