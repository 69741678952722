import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { IUserInfo } from '~/services/generated/DispatcherClient';
import { getUser } from './user.api';

interface UserStore {
  user?: IUserInfo;
  loading: boolean;
  error: boolean;
  actions: {
    fetch: () => Promise<void>;
  };
}

export const useUserStore = create<UserStore>()(
  devtools<UserStore>(
    (set) => ({
      user: undefined,
      loading: true,
      error: false,
      actions: {
        fetch: async () => {
          set({ loading: true, error: false }, false, 'Fetching User');
          const { user } = await getUser();
          if (user) {
            set(
              {
                user,
                error: false,
                loading: false,
              },
              false,
              'Fetched User',
            );
          } else {
            set({
              user: undefined,
              error: true,
              loading: false,
            });
          }
        },
      },
    }),
    {
      name: 'UserStore',
      anonymousActionType: `User`,
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useUserActions = () => useUserStore((state) => state.actions);
