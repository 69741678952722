// TODO: Missing test

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { logger } from '~/logging';
import { ErrorBoundaryComponent } from './error-boundary.component';

type ErrorBoundaryState = { hasError: boolean };

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    } as { hasError: boolean };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    logger.error('ErrorBoundary caught an error:', error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorBoundaryComponent />;
    }
    return children;
  }
}
