const swedish = new Intl.Collator('sv');

type KeyOf<T> = keyof T;

/**
 * Creates a comparator function to sort objects by a specified key using Swedish locale string comparison.
 *
 * @example ```ts
 * const sorted = [{ name: 'Orminge' }, { name: 'Österbybruk' }].sort(sortBy('name'));
 *
 *
 */
export function sortBy<T>(key: KeyOf<T>) {
  return (a: T, b: T) => swedish.compare(a[key] as unknown as string, b[key] as unknown as string);
}
