export interface GlobalStore {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  updated: number;
}

export const initialGlobalState: GlobalStore = {
  loading: false,
  error: false,
  errorMessage: undefined,
  updated: 0,
};
