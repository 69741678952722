import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { formatPrice } from '@aph/utilities/format-price/format-price';
import type { IArticle, Price, Tag } from '~/articles/generated/ArticlesClient';
import {
  PriceFormatter,
  type PriceFormatterProps,
} from '../new-price/price-formatter/price-formatter.component';

export const PricesGroup: React.FC<{
  price?: Price;
  tags?: Tag[];
  size?: PriceFormatterProps['size'];
  spread?: boolean;
  showOnlyOnePrice?: boolean;
  loading?: boolean;
}> = ({ price, tags, size, spread, showOnlyOnePrice, loading }) => {
  const { webPrice, salesPrice } = price || {};

  if (loading) {
    return (
      <Skeleton height={41}>
        <PriceFormatter
          price={100}
          size={size}
          label={<FormattedMessage id="ARTICLE.CAMPAIGN" />}
        />
      </Skeleton>
    );
  }

  if (!webPrice) return null;

  if (salesPrice) {
    const isClubPrice = tags?.find((tag) => tag.type === 'ClubMemberFlyer');

    return (
      <Stack
        justifyContent={spread ? 'space-between' : 'flex-start'}
        width={spread ? '100%' : undefined}
        direction="row"
        gap={spread ? 1 : 3}
        alignItems="flex-end"
        data-testid="root"
      >
        <PriceFormatter
          price={salesPrice}
          size={size}
          color={isClubPrice ? 'text-brand' : 'text-notice'}
          label={<FormattedMessage id={isClubPrice ? 'ARTICLE.CLUB' : 'ARTICLE.CAMPAIGN'} />}
        />
        {!showOnlyOnePrice && (
          <PriceFormatter
            price={webPrice}
            size={size}
            strikeThrough
            label={<FormattedMessage id="ARTICLE.WEB" />}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent={spread ? 'space-between' : 'flex-start'}
      width={spread ? '100%' : undefined}
      direction="row"
      gap={spread ? 1 : 3}
      data-testid="root"
    >
      <PriceFormatter
        price={webPrice}
        size={size}
        color="text-brand"
        label={<FormattedMessage id="ARTICLE.WEB" />}
      />
    </Stack>
  );
};

export const PricesGroupContainer = ({
  product,
  loading,
}: {
  product: IArticle;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <Stack direction="row" gap={1} height={50}>
        <Skeleton>
          <Typography typography="priceLarge">priser</Typography>
        </Skeleton>
        <Skeleton>
          <Typography typography="priceLarge">priser</Typography>
        </Skeleton>
      </Stack>
    );
  }

  if (!product.price) {
    return null;
  }

  const { price, isWebSaleOnly } = product;
  const { retailPrice, webPrice = 0 } = price;
  const showRetailPriceComponent = retailPrice && retailPrice > webPrice && !isWebSaleOnly;

  return (
    <Stack gap={1}>
      <PricesGroup price={price} />
      <div className="flex flex-col">
        {price.comparisonPrice?.pricePerUnit && (
          <Typography typography="subHeading" color="text-subtle">
            {formatPrice(price.comparisonPrice?.pricePerUnit)}
            {price.comparisonPrice.unit ? (
              <FormattedMessage id={`ARTICLE.COMPARISON.UNIT.${price.comparisonPrice.unit}`} />
            ) : undefined}
          </Typography>
        )}
        {showRetailPriceComponent && (
          <Typography typography="subHeading" color="text-subtle">
            <FormattedMessage id="ARTICLE.RETAIL" values={{ price: '' }} />
            {formatPrice(retailPrice)}
          </Typography>
        )}
      </div>
    </Stack>
  );
};
