import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { container } from 'tsyringe';
import { v4 as uuidv4 } from 'uuid';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

const USE_GET_PERSONAL_OFFERS_QUERY_KEY = 'get-personal-offers';

type UseGetPersonalOffersQueryProps = {
  enabled: boolean | undefined;
};

export const useGetPersonalOffersQuery = ({ enabled }: UseGetPersonalOffersQueryProps) => {
  const correlationId = useMemo(() => uuidv4(), []);

  const result = useQuery({
    queryKey: [USE_GET_PERSONAL_OFFERS_QUERY_KEY],
    queryFn: () => {
      const customerClient = container.resolve(CustomerClient);
      return customerClient.getPersonalOffersV3(correlationId, 'aphTinman', undefined);
    },
    retry: false,
    staleTime: Infinity,
    enabled,
  });

  return result;
};
