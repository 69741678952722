import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import type { ButtonProps } from '@aph/ui/components/button/button';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';

type ProfileMenuProps = {
  buttonSize?: ButtonProps['size'];
  children: React.ReactNode;
  isAuthenticated?: boolean | null;
  shouldShowPersonalOffersBadge?: boolean;
  onOpenChange?: (open: boolean) => void;
  onLogin: () => Promise<boolean>;
};

export const ProfileMenuComponent = ({
  buttonSize = 'small',
  children,
  isAuthenticated,
  shouldShowPersonalOffersBadge,
  onOpenChange,
  onLogin,
}: ProfileMenuProps) => {
  const intl = useIntl();

  if (!isAuthenticated) {
    return (
      <>
        <span className="hidden md:block">
          <Button className="select-none" size={buttonSize} variant="secondary" onClick={onLogin}>
            <Icon name="Profile" size="small" />
            <FormattedMessage id="COMMON.HEADER.PROFILE.BUTTON.TEXT.LOGIN" />
          </Button>
        </span>
        <span className="block md:hidden">
          <ButtonCircle
            className="select-none"
            size="medium"
            variant="secondary"
            icon="Profile"
            text={intl.formatMessage({ id: 'COMMON.HEADER.PROFILE.BUTTON.TEXT.LOGIN' })}
            onClick={onLogin}
          />
        </span>
      </>
    );
  }

  return (
    <>
      <span className="hidden md:block">
        <DropdownMenu.Root modal={false} onOpenChange={onOpenChange}>
          <DropdownMenu.Trigger asChild>
            <Button className="relative select-none" size={buttonSize} variant="secondary">
              <Icon name="Profile" size="small" />
              <FormattedMessage id="COMMON.HEADER.PROFILE.BUTTON.TEXT" />
              {shouldShowPersonalOffersBadge && (
                <span
                  className="bg-notice-dark border-inverse pointer-events-none absolute right-0.5 top-0.5 box-content h-1 w-1 -translate-y-1/2 translate-x-1/2 rounded-full border-[1px]"
                  aria-hidden
                />
              )}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>{children}</DropdownMenu.Portal>
        </DropdownMenu.Root>
      </span>
      <span className="block md:hidden">
        <DropdownMenu.Root modal={false} onOpenChange={onOpenChange}>
          <DropdownMenu.Trigger asChild>
            <ButtonCircle
              className="select-none"
              size="medium"
              variant="secondary"
              icon="Profile"
              text={intl.formatMessage({ id: 'COMMON.HEADER.PROFILE.BUTTON.TEXT' })}
              badge={
                shouldShowPersonalOffersBadge && (
                  <span
                    className="bg-notice-dark border-inverse pointer-events-none absolute right-0.5 top-0.5 box-content h-1 w-1 -translate-y-1/2 translate-x-1/2 rounded-full border-[1px]"
                    aria-hidden
                  />
                )
              }
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>{children}</DropdownMenu.Portal>
        </DropdownMenu.Root>
      </span>
    </>
  );
};
