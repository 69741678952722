import React from 'react';
import type { IArticleReference } from '~/articles/generated/ArticlesClient';
import { ProductCard } from '../../product-card/product-card';

type ProducSearchResultsProps = {
  articles: Array<IArticleReference> | undefined;
};
export const ProducSearchResults: React.FC<ProducSearchResultsProps> = ({ articles }) => {
  if (articles?.length) {
    return (
      <ul className="flex flex-col divide-y-2">
        {articles.map((article, index) => (
          <li key={article.articleCode} className="border-subtle p-2">
            <ProductCard
              variant="searchresult"
              article={article}
              index={index + 1}
              listName="sökresultat"
            />
          </li>
        ))}
      </ul>
    );
  }

  return null;
};
