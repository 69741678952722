import React from 'react';
import { SvgIcon } from '@mui/material';
import { useIntl } from 'react-intl';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';
import Logo from './logo.svg';

export const LogoLink = () => {
  const intl = useIntl();

  return (
    <FeatureToggledNextLink
      forceAnchorLink
      href="/"
      title={intl.messages['COMMON.HEADER.LOGO_LINK_TITLE'].toString()}
      aria-label={intl.messages['COMMON.HEADER.LOGO_LINK_TITLE'].toString()}
      sx={{
        lineHeight: 1,
        '&:focus-visible': {
          outline: (theme) => `1px solid ${theme.palette['color/border/action']}}`,
          outlineOffset: '2px',
        },
      }}
    >
      <SvgIcon
        component={Logo}
        inheritViewBox
        sx={{
          fontSize: 'unset',
          width: 'unset',
          height: '30px',
        }}
      />
    </FeatureToggledNextLink>
  );
};
