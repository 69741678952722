import React from 'react';
import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { useCartStore } from '~/model/cart/cart.store';
import { CostType } from '~/services/generated/PurchaseClient';
import { PriceFormatter } from '../price/price-formatter.component';

export const MiniCartSummary = () => {
  const { cart } = useCartStore();
  const mappedPriceDetailFromCostType = (costType: CostType) =>
    cart?.priceDetails?.find((x) => x.type === costType)?.value || 0;

  const totalDiscount = mappedPriceDetailFromCostType(CostType.TotalDiscount);
  const totalCostExcludingShipping = mappedPriceDetailFromCostType(CostType.TotalExcludingShipping);
  const totalBonus = mappedPriceDetailFromCostType(CostType.TotalBonus);

  const bonusVouchers = cart?.bonusVouchers && cart?.bonusVouchers.length > 0;

  return (
    <>
      <Stack gap={0.5}>
        {cart?.promotions ? (
          <Stack direction="column" gap={0.5}>
            {cart.promotions.map((promotion) => (
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                textAlign="right"
              >
                <Typography key={promotion.promotionDisplayName}>
                  {promotion.promotionDisplayName}
                </Typography>
                {promotion.salesPrice ? (
                  <PriceFormatter
                    price={-promotion.salesPrice}
                    inline
                    size="small"
                    color="text-notice"
                  />
                ) : null}
              </Stack>
            ))}
          </Stack>
        ) : null}
        {totalDiscount ? (
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            textAlign="right"
          >
            <Typography>
              <FormattedMessage id="MINICART.DISCOUNT" />
            </Typography>
            <PriceFormatter price={totalDiscount} inline size="small" />
          </Stack>
        ) : null}
        {bonusVouchers ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            textAlign="right"
          >
            <Typography>
              <FormattedMessage id="SUMMARY.VOUCHER" />
            </Typography>
            <PriceFormatter price={-totalBonus} inline size="small" color="text-notice" />
          </Stack>
        ) : null}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        mb={1}
        alignItems="center"
        textAlign="right"
      >
        <Typography typography="headingSmall">Totalt</Typography>
        <PriceFormatter price={totalCostExcludingShipping} inline size="large" />
      </Stack>
    </>
  );
};
