import type { PropsWithChildren } from 'react';
import { Drawer, type DrawerProps, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@aph/components/common/icon-button/icon-button.component';
import CloseIcon from '@aph/icons/close.svg';
import { Typography } from '@aph/ui/components/typography/typography';

type SideDrawerProps = PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
}> &
  Pick<DrawerProps, 'anchor'>;

export const SideDrawer = ({
  title,
  children,
  isOpen,
  onClose,
  anchor = 'right',
}: SideDrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor={anchor}
      PaperProps={{
        sx:
          anchor === 'right'
            ? { borderTopLeftRadius: 24, borderBottomLeftRadius: 24 }
            : { borderTopRightRadius: 24, borderBottomRightRadius: 24 },
      }}
    >
      <Stack width={{ xs: 320, md: 400 }} height="100%">
        <div className="border-subtle flex items-center justify-between border-b px-2 py-3">
          <Typography typography="headingExtraLarge" color="text-brand" asChild>
            <h2>{title}</h2>
          </Typography>
          <IconButton
            icon={CloseIcon}
            color={theme.palette['color/text/default']}
            onClick={onClose}
          />
        </div>
        {children}
      </Stack>
    </Drawer>
  );
};
