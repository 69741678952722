import React from 'react';
import type { ButtonBaseProps } from '@mui/material';
import { ButtonBase as MUIButtonBase, SvgIcon } from '@mui/material';

type NativeButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type IconButtonProps = Pick<ButtonBaseProps, 'sx'> &
  NativeButtonProps & {
    icon: React.ElementType;
    size?: 'small' | 'medium';
  };

export const IconButton = ({ icon, size, sx = [], ...props }: IconButtonProps) => (
  <MUIButtonBase
    sx={[
      {
        color: (theme) => theme.palette['color/icon/action'],
        fontSize: '32px',
        '&:hover': {
          color: (theme) => theme.palette['color/text/action-dark'],
        },
      },
      size === 'small' && {
        fontSize: '24px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
    disableRipple
  >
    <SvgIcon inheritViewBox component={icon} />
  </MUIButtonBase>
);
