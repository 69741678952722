import { sendEvent } from '../useGtm';

export function parseEventTree(href: string) {
  if (href === '/') {
    return ['hem'];
  }
  return href.split('/').filter((item) => !['', 'produkter'].includes(item));
}

export const sendMainNavigationEvent = (eventTree: string[]) => {
  if (eventTree.length < 1) {
    return;
  }

  const tree = {
    menu_level_1: eventTree[0],
    menu_level_2: eventTree[1],
    menu_level_3: eventTree[2],
  };

  sendEvent({
    event: 'main_navigation',
    ...tree,
  });
};
