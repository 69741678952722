import React from 'react';
import NextLink from 'next/link';
import { Box, Link as MuiLink, Skeleton, Stack } from '@mui/material';
import set from 'lodash/set';
import {
  AddArticleToCartComponent,
  type AddArticleToCartProps,
} from '@aph/components/product-variant/add-article-to-cart-button/add-article-to-cart.component';
import { PricesGroup } from '@aph/components/product-variant/prices-group/prices-group.component';
import { useIsBot } from '@aph/hooks/use-is-bot';
import { Typography } from '@aph/ui/components/typography/typography';
import { formatProductUrl } from '@aph/utilities/format-product-url';
import { ArticleImage } from '~/articles/components/article-image/article-image.component';
import { ArticleProductType } from '~/articles/components/article-product-type/article-product-type';
import type { IArticleReference } from '~/articles/generated/ArticlesClient';
import { useArticleCardTracking } from '~/articles/hooks/use-article-card-tracking/use-article-card-tracking';

type ProductCardVariant = 'minicart' | 'searchresult';

type ProductCardProps =
  | {
    article: IArticleReference;
    loading?: boolean;
    listName: string;
    index: number;
    variant?: ProductCardVariant;
  }
  | {
    article?: never;
    loading: true;
    listName?: never;
    index?: never;
    variant?: ProductCardVariant;
  };

type VariantProductCardProps = {
  isLoading: boolean | undefined;
  article: IArticleReference | undefined;
  onClick: () => void;
};

const MiniCartProductCard = React.forwardRef<
  React.ElementRef<typeof Box>,
  VariantProductCardProps & Pick<AddArticleToCartProps, 'listName' | 'index'>
>(({ article, listName, isLoading, index, onClick }, forwardedRef) => (
  <Box
    ref={forwardedRef}
    padding={2}
    bgcolor={(theme) => theme.palette['color/background/elevated']}
    border="1px solid"
    borderColor={(theme) => theme.palette['color/border/default']}
    borderRadius={3}
    data-testid="MiniCartProductCard"
  >
    <NextLink
      href={{
        pathname: formatProductUrl(article?.articleUrlSegment),
        query: article?.trackingId ? { ri: article.trackingId } : undefined,
      }}
      legacyBehavior
      passHref
      prefetch={false}
    >
      <MuiLink underline="none" flexGrow={1} onClick={onClick}>
        <Stack direction="row" columnGap={2}>
          <Box flexShrink={0} position="relative">
            {isLoading ? (
              <Skeleton variant="rectangular" height={72} />
            ) : (
              <ArticleImage
                layout="constrained"
                height={72}
                width={72}
                objectFit="contain"
                src={article?.images?.[0]}
                alt={article?.name ?? ''}
                shouldUseRxFallback={article?.requiresPrescriptionForPurchase}
              />
            )}
          </Box>

          <Stack rowGap={2} flex={1} minWidth={0}>
            <Stack>
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <>
                  <Typography typography="headingSmall" className="no-wrap">
                    {article?.name}
                  </Typography>
                  <ArticleProductType productType={article?.productType} />
                </>
              )}
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
              {isLoading ? (
                <>
                  <Skeleton variant="text" width={50} height={24} />
                  <Skeleton variant="rounded" width="50%" height={24} />
                </>
              ) : (
                <>
                  <PricesGroup
                    price={article?.price}
                    tags={article?.tags}
                    size="medium"
                    showOnlyOnePrice
                  />
                  <Box onClick={(event) => event.preventDefault()} marginLeft={2} flex={1}>
                    <AddArticleToCartComponent
                      article={article}
                      size="small"
                      index={index}
                      listName={listName}
                    />
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </MuiLink>
    </NextLink>
  </Box>
));

const SearchResultProductCard = React.forwardRef<
  React.ElementRef<typeof Box>,
  VariantProductCardProps
>(({ article, isLoading, onClick }, forwardedRef) => (
  <Box ref={forwardedRef} data-testid="SearchResultProductCard">
    <NextLink
      href={{
        pathname: formatProductUrl(article?.articleUrlSegment),
        query: article?.trackingId ? { ri: article.trackingId } : undefined,
      }}
      legacyBehavior
      passHref
      prefetch={false}
    >
      <MuiLink underline="none" flexGrow={1} onClick={onClick}>
        <Stack direction="row" columnGap={2}>
          <Box flexShrink={0}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={72} />
            ) : (
              <ArticleImage
                layout="constrained"
                height={72}
                width={72}
                objectFit="contain"
                src={article?.images?.[0]}
                alt={article?.name ?? ''}
                shouldUseRxFallback={article?.requiresPrescriptionForPurchase}
              />
            )}
          </Box>

          <Stack flex={1} minWidth={0}>
            <Stack>
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <>
                  <Typography typography="headingSmall" className="no-wrap">
                    {article?.name}
                  </Typography>
                  <ArticleProductType productType={article?.productType} />
                </>
              )}
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
              {isLoading ? (
                <Skeleton variant="text" width={50} height={24} />
              ) : (
                <PricesGroup price={article?.price} size="small" showOnlyOnePrice />
              )}
            </Stack>
          </Stack>
        </Stack>
      </MuiLink>
    </NextLink>
  </Box>
));

export const ProductCard = ({ article, loading, listName, index, variant }: ProductCardProps) => {
  const { sendSelectItemEvent, cardRef } = useArticleCardTracking(article, listName, index);
  const isBot = useIsBot();

  const isMiniCartVariant = variant === 'minicart';
  const isSearchResultVariant = variant === 'searchresult';

  if (isBot && article) {
    // we don't want to use trackingId for bots (SEO)
    set(article, 'trackingId', undefined);
  }

  if (isMiniCartVariant) {
    return (
      <MiniCartProductCard
        ref={cardRef}
        index={index || 0}
        article={article}
        isLoading={loading}
        listName={listName}
        onClick={sendSelectItemEvent}
      />
    );
  }

  if (isSearchResultVariant) {
    return (
      <SearchResultProductCard
        ref={cardRef}
        article={article}
        isLoading={loading}
        onClick={sendSelectItemEvent}
      />
    );
  }

  return null;
};
