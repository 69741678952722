import type { BalanceV4 } from '~/services/generated/CustomerClient';

export type BonusBalanceState = {
  data: BalanceV4 | null;
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
  updated: number;
};

export const initialState: BonusBalanceState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  updated: 0,
};
