import type { PropsWithChildren } from 'react';

export const DevelopmentOnly = ({ children }: PropsWithChildren) => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (!isDevelopment) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
