import { FormattedMessage } from 'react-intl';
import { Icon } from '@aph/ui/components/icon/icon';
import { Typography } from '@aph/ui/components/typography/typography';
import { type FooterSocialLogosLinkType, footerSocialLogosLinks } from '../links/links-data';

export const SocialIcons = () => (
  <div className="mt-3 flex flex-col gap-2 sm:mt-0">
    <Typography color="text-inverse" className="font-bold">
      <FormattedMessage id="COMMON.FOOTER.SOCIAL.FOLLOW" />
    </Typography>
    <div className="flex gap-1">
      {footerSocialLogosLinks.map((link: FooterSocialLogosLinkType) => (
        <a
          key={link.icon}
          className="bg-default outline-default hover:bg-elevated flex h-5 w-5 items-center justify-center rounded-full outline-2 outline-offset-2 focus-visible:outline"
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={link.ariaLabel}
        >
          <Icon name={link.icon} fill="fill-action-dark" aria-hidden />
        </a>
      ))}
    </div>
  </div>
);
