import React from 'react';
import { cva } from 'class-variance-authority';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import type { TypographyProps } from '@aph/ui/components/typography/typography';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { CustomVariantProps } from '@aph/ui/tailwind/variant-props';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';

const variations = cva(
  'hover:bg-default focus-visible:outline-action-dark flex break-words rounded-md px-1 py-0.5 no-underline',
  {
    variants: {
      variant: {
        primary: 'text-action-dark data-[active=true]:bg-visual font-black',
        secondary:
          'text-default data-[active=true]:bg-action-dark data-[active=true]:text-inverse-dark focus-visible:outline-offset-2',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

export interface FeatureToggleableLink {
  href: string;
  isEpiLink?: boolean;
  feature?: string;
  title: string;
}

export type LinkListProps = {
  links: FeatureToggleableLink[];
  onLinkClick?: (target: FeatureToggleableLink) => void;
  typography?: Extract<TypographyProps['typography'], 'body' | 'bodyBig'>;
  isActiveStrategy?: 'partial' | 'exact';
} & CustomVariantProps<typeof variations> &
  React.HTMLAttributes<HTMLUListElement>;

export const LinkList = ({
  links,
  variant,
  onLinkClick,
  typography = 'body',
  isActiveStrategy = 'exact',
  className,
  ...props
}: LinkListProps) => {
  const isActiveLink = useMatchRoute();

  const isBodyBigTypography = typography === 'bodyBig';

  return (
    <ul className={cn('flex flex-col gap-y-1', className)} {...props}>
      {links.map((link) => (
        <li key={link.href}>
          <Typography
            data-active={isActiveLink(link.href, { exact: isActiveStrategy === 'exact' })}
            typography={typography}
            className={cn(variations({ variant }), { 'py-1': isBodyBigTypography })}
            asChild
          >
            <FeatureToggledNextLink
              href={link.href}
              forceAnchorLink={link.isEpiLink}
              featureName={link.feature}
              onClick={() => onLinkClick?.(link)}
            >
              {link.title}
            </FeatureToggledNextLink>
          </Typography>
        </li>
      ))}
    </ul>
  );
};
