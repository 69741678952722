import { Box } from '@mui/material';
import { Typography } from '@aph/ui/components/typography/typography';
import type { FooterLinkType } from '../links/links-data';
import type { LinksProps } from './footer-links.component';
import { LinkList } from './link-list.component';

interface DesktopLinksProps extends LinksProps {
  footerLinkClickEvent: (args: { link: FooterLinkType }) => void;
}

export const DesktopLinks = ({ header, links, footerLinkClickEvent }: DesktopLinksProps) => {
  return (
    <>
      <Box paddingBottom={1}>
        <Typography typography="body" className="pl-1 font-bold" color="text-inverse" asChild>
          <h2>{header.title}</h2>
        </Typography>
      </Box>
      <LinkList links={links} onLinkClick={footerLinkClickEvent} />
    </>
  );
};
