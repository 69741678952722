import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import { type ArticleReference, TagType } from '~/articles/generated/ArticlesClient';
import { ErrorDescription } from '~/services/generated/CustomerClient';
import type { OfferResponse } from '~/services/generated/PresentationClient';

export const getErrorOrDefault = (
  error: unknown,
  errorIntlKey: string,
): ErrorIntlKey | (ErrorMessage & ErrorCode) => {
  if (error instanceof ErrorDescription && error.userMessage && error.userMessage.length !== 0) {
    const errorCode = error.code || '';
    const errorMessage = error.userMessage.join(' ');
    return { errorMessage, errorCode };
  }
  return { errorIntlKey };
};

export const getArticlesFromPersonalOffers = (personalOffers?: OfferResponse[]) => {
  const personalOffersArticles = personalOffers?.reduce((acc: ArticleReference[], curr) => {
    let articlesArray: ArticleReference[] = [];
    if (curr.articles) {
      articlesArray = [...acc, ...curr.articles];
    }
    return articlesArray;
  }, []);

  return personalOffersArticles?.map((article) => {
    if (article.price?.salesInformation?.flyerInformation?.text) {
      return {
        ...article,
        tags: [
          {
            type: TagType.Flyer,
            value: article.price.salesInformation.flyerInformation.text,
          },
        ],
      };
    }
    return article;
  }) as ArticleReference[] | undefined;
};
