import React from 'react';
import { Typography } from '@aph/ui/components/typography/typography';
import type { IAphChecklistFields } from '~/contentful/types';
import { AphChecklistIcon } from './aph-checklist-icon.component';

type AphChecklistProps = {
  list: IAphChecklistFields;
};

export const AphChecklist = ({
  list: { listItems, iconVariant, disclaimer },
}: AphChecklistProps) => (
  <ul className="not-rich-text--component my-2 flex flex-col gap-2">
    {listItems.map((item) => (
      <li className="flex gap-1.5" key={item}>
        <AphChecklistIcon iconVariant={iconVariant} />
        <Typography>{item}</Typography>
      </li>
    ))}
    {disclaimer?.map((disclaimerItem) => (
      <li key={disclaimerItem} className="self-end">
        <Typography typography="body" className="italic">
          {disclaimerItem}
        </Typography>
      </li>
    ))}
  </ul>
);
