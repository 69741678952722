import type { Asset } from 'contentful';
import { ContentfulImage } from '../../contentful-image/contentful-image';

type AssetImageProps = {
  image: Asset;
  alt?: string;
};
export const AssetImage = ({ image, alt }: AssetImageProps) => {
  const { description, title, file } = image.fields;

  // some images are not processed in contentful and then the url is not available
  if (file.url) {
    const { url, details } = file;

    if (details?.image) {
      return (
        <ContentfulImage
          src={url}
          alt={alt ?? description ?? title}
          width={details.image.width}
          height={details.image.height}
          layout="constrained"
        />
      );
    }

    return null;
  }

  return null;
};
