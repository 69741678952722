import { Accordion } from '@aph/ui/components/accordion/accordion';
import type { FooterLinkType } from '../links/links-data';
import type { LinksProps } from './footer-links.component';
import { LinkList } from './link-list.component';

interface MobileLinksProps extends LinksProps {
  footerLinkClickEvent: (args: { link: FooterLinkType }) => void;
}
export const MobileLinks = ({ links, header, footerLinkClickEvent }: MobileLinksProps) => {
  return (
    <div className="mx-1">
      <Accordion
        items={[
          {
            title: header.title,
            value: header.title,
            children: (
              <LinkList className="gap-2" links={links} onLinkClick={footerLinkClickEvent} />
            ),
          },
        ]}
        variant="inverted"
      />
    </div>
  );
};
