import { container } from 'tsyringe';
import { logger } from '~/logging';
import type { IUserInfo } from '~/services/generated/DispatcherClient';
import { Client as DispatcherClient } from '~/services/generated/DispatcherClient';

export const getUser: () => Promise<{ user: IUserInfo }> = async () => {
  const client = container.resolve(DispatcherClient);
  try {
    const currentUserInfo = await client.getUserInfo();
    return {
      user: currentUserInfo,
    };
  } catch (error) {
    logger.error(`user.api.ts failed to fetch user info from service ${JSON.stringify(error)}`);
    return error;
  }
};
