/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Skeleton } from '@mui/material';
import { useIntl } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useToggle } from '@aph/hooks/use-toggle';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { ArticleCategoryNavigation } from '~/articles/components/article-category-navigation/article-category-navigation.component';
import { SideDrawer } from '~/articles/components/side-drawer/side-drawer';
import type { FeatureToggleableLink } from '../link-list/link-list.component';
import { LinkList } from '../link-list/link-list.component';
import { useGetPromotedLinks } from '../link-list/use-get-promoted-links';
import { TOP_NAVIGATION_LINKS } from './top-navigation.component';

export const HamburgerMenu = () => {
  const [isMenuExpanded, toggle, setIsMenuExpanded] = useToggle();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { data: PROMOTED_LINKS, error } = useGetPromotedLinks();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuExpanded(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, setIsMenuExpanded, toggle]);

  const handleLinkClick = (target: FeatureToggleableLink) =>
    sendMainNavigationEvent(parseEventTree(target.href));

  let promotedLinksComponent: React.ReactNode = (
    <div className="my-1.5 flex flex-col gap-y-2">
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
    </div>
  );

  if (PROMOTED_LINKS) {
    promotedLinksComponent = (
      <LinkList links={PROMOTED_LINKS} onLinkClick={handleLinkClick} className="my-1.5" />
    );
  }

  if (error) {
    promotedLinksComponent = null;
  }

  return (
    <>
      <ButtonCircle
        size="extraLarge"
        icon="Menu"
        onClick={toggle}
        variant="secondary"
        aria-label="Öppna meny"
      />
      <SideDrawer
        title={formatMessage({ id: 'COMMON.HEADER.MENU' })}
        isOpen={isMenuExpanded}
        onClose={toggle}
        anchor="left"
      >
        <nav className="flex flex-col gap-2 px-1">
          <LinkList
            links={TOP_NAVIGATION_LINKS}
            variant="secondary"
            onLinkClick={handleLinkClick}
            typography="bodyBig"
            className="mt-1"
          />

          {promotedLinksComponent}

          <div className="my-1.5">
            <ArticleCategoryNavigation />
          </div>
        </nav>
      </SideDrawer>
    </>
  );
};
