import { Box, type BoxProps } from '@mui/material';

const SIDEBAR_WIDTH = {
  md: '216px',
  xl: '248px',
} as const;

const CONTENT_WIDTH = {
  xxl: '1400px',
  xl: '1112px',
} as const;

const GRID_MARGIN = {
  xs: '12px',
  sm: '24px',
  md: '40px',
  xl: '72px',
  xxl: '120px',
} as const;

const GRID_GUTTER = {
  md: 3,
  lg: 4,
} as const;

// maybe if this is moved to the theme or refactored we can skip the * 8 hardcoding...but for now it works at least
const gutterInPixels = (gutter: number) => `${gutter * 8}px`;

const CONTAINER_WIDTH = {
  xl: `calc(${CONTENT_WIDTH.xl} + ${gutterInPixels(GRID_GUTTER.lg)} + (${GRID_MARGIN.xl} * 2) + ${
    SIDEBAR_WIDTH.xl
  })`,
  xxl: `calc(${CONTENT_WIDTH.xxl} + ${gutterInPixels(GRID_GUTTER.lg)} + (${
    GRID_MARGIN.xxl
  } * 2) + ${SIDEBAR_WIDTH.xl})`,
} as const;

const PageLayoutContainer = ({ children, ...props }: BoxProps) => (
  <Box width="100%" position="relative" {...props}>
    <Box width={CONTAINER_WIDTH} marginX="auto" paddingX={GRID_MARGIN}>
      {children}
    </Box>
  </Box>
);

export { PageLayoutContainer, GRID_MARGIN, CONTENT_WIDTH };
