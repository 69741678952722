import { sendEvent } from '../useGtm';

export const sendSearchEvent = (searchTerm: string) => {
  sendEvent({
    event: 'search',
    search_term: searchTerm,
  });
};

export const sendQuickSearchEvent = (searchTerm: string) => {
  sendEvent({
    event: 'quick_search',
    search_term: searchTerm,
  });
};
