import type { CartEvent } from '@aph/components/gtm/events/cart-gtm';
import type { IPrescriptionDetails } from '~/services/generated/PurchaseClient';

export const createRxEventData = (
  prescriptionDetails: IPrescriptionDetails,
  oldQuantity: number,
  newQuantity: number,
): CartEvent => ({
  isSensitive: true,
  name: 'Receptköp',
  articleCode: 'prescriptionItem',
  category: [prescriptionDetails?.isForAnimal ? 'Animal' : 'Human'],
  oldQuantity,
  newQuantity,
});
