import { container } from 'tsyringe';
import { logger } from '~/logging';
import {
  CartsClient,
  CreateLineItemRequest,
  ProblemDetails,
  UpdateLineItemRequest,
} from '~/services/generated/PurchaseClient';
import type {
  ICart,
  ICreateLineItemRequest,
  IUpdateLineItemRequest,
} from '~/services/generated/PurchaseClient';
import type { ApiLineItemType, ApiRxLineItemType } from './types/lineItem';

export const getCart: () => Promise<{
  cart?: ICart;
  errorMessage?: string;
}> = async () => {
  const client = container.resolve(CartsClient);
  try {
    const result = await client.getCart('1', undefined, undefined, undefined);
    return {
      cart: result,
    };
  } catch (e) {
    logger.error(`cart.api.ts failed to fetch cart from service ${JSON.stringify(e)}`);
    if (e instanceof ProblemDetails)
      return {
        errorDescription: e,
        errorMessage: e.detail, // TODO: Remove this when we have a proper error handling
      };
    return {
      errorMessage: 'Något gick fel, försök igen', // TODO: Remove this when we have a proper error handling
    };
  }
};

export const deleteCart: (cartId: string) => Promise<void> = async (cartId) => {
  const client = container.resolve(CartsClient);
  try {
    await client.deleteCart(cartId, '1', undefined, undefined, undefined);
  } catch (e) {
    logger.error(`cart.api.ts failed to delete cart from service ${JSON.stringify(e)}`);
  }
};

export const deleteCartLineItem: (
  props: ApiLineItemType,
) => Promise<{ cart?: ICart; errorMessage?: string }> = async ({ cartId, lineItemId }) => {
  const client = container.resolve(CartsClient);

  try {
    const result = await client.deleteCartLineItem(
      cartId,
      lineItemId,
      '1',
      undefined,
      undefined,
      undefined,
    );
    return {
      cart: result,
    };
  } catch (e) {
    logger.error(`cart.api.ts failed to delete item from cart from service ${JSON.stringify(e)}`);
    if (e instanceof ProblemDetails)
      return {
        errorDescription: e,
        errorMessage: e.detail, // TODO: Remove this when we have a proper error handling
      };
    return {
      errorMessage: 'Något gick fel, försök igen', // TODO: Remove this when we have a proper error handling
    };
  }
};

const createCartLineItem: (
  props: ApiLineItemType,
  data: ICreateLineItemRequest,
) => Promise<{ cart?: ICart; errorMessage?: string }> = async (
  { cartId, recommendationsInfo },
  data,
) => {
  const client = container.resolve(CartsClient);
  if (!data.articleCode) throw new Error();
  try {
    const result = await client.createCartLineItem(
      cartId,
      '1',
      recommendationsInfo,
      undefined,
      undefined,
      new CreateLineItemRequest(data),
    );
    return {
      cart: result,
    };
  } catch (e) {
    logger.error(`cart.api.ts failed to add item to cart from service ${JSON.stringify(e)}`);
    // TODO: this should be Promise.reject instead with the error message
    if (e instanceof ProblemDetails)
      return {
        errorDescription: e,
        errorMessage: e.detail, // TODO: Remove this when we have a proper error handling
      };
    return {
      errorMessage: 'Något gick fel, försök igen', // TODO: Remove this when we have a proper error handling
    };
  }
};

export const updateCartLineItem: (
  props: ApiLineItemType,
  data: IUpdateLineItemRequest,
) => Promise<{ cart?: ICart; errorMessage?: string }> = async (
  { cartId, lineItemId, recommendationsInfo },
  data,
) => {
  const client = container.resolve(CartsClient);
  try {
    const result = await client.updateCartLineItem(
      cartId,
      lineItemId,
      '1',
      recommendationsInfo,
      undefined,
      undefined,
      new UpdateLineItemRequest(data),
    );
    return {
      cart: result,
    };
  } catch (e) {
    logger.error(`cart.api.ts failed to update item in cart from service ${JSON.stringify(e)}`);
    // TODO: this should be Promise.reject instead with the error message
    if (e instanceof ProblemDetails)
      return {
        errorDescription: e,
        errorMessage: e.detail, // TODO: Remove this when we have a proper error handling
      };
    return {
      errorMessage: 'Något gick fel, försök igen', // TODO: Remove this when we have a proper error handling
    };
  }
};

export type UpdateOrCreateLineItemProps = ApiLineItemType & ApiRxLineItemType;

export const updateOrCreateLineItem: (
  props: UpdateOrCreateLineItemProps,
) => Promise<{ cart?: ICart; errorMessage?: string }> = async (props) => {
  const { lineItemId, cartId, quantity, recommendationsInfo, ...rest } = props;

  // TODO: this is something we should fix, we're basically checking if a string has length > 0 since we force lineItemId to be a string we need to send '' to this method to make it work
  // the lineItemId parameter should probably be optional instead
  if (lineItemId) {
    const updatedLineItemCartResult = await updateCartLineItem(
      { cartId, lineItemId, recommendationsInfo },
      { quantity },
    );
    return updatedLineItemCartResult;
  }

  const createdLineItemCartResult = await createCartLineItem(
    { cartId, lineItemId, recommendationsInfo },
    { quantity, ...rest },
  );
  return createdLineItemCartResult;
};
