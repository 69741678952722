import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  resumeDeliveryWidget,
  suspendDeliveryWidget,
} from '@aph/utilities/delivery/suspend-resume-widget';
import {
  resumePaymentWidget,
  suspendPaymentWidget,
} from '@aph/utilities/payment/suspend-resume-widget';
import type { GlobalStore } from '~/model/initial-global-state';
import { initialGlobalState } from '~/model/initial-global-state';
import type { ICart, LineItem, PrescriptionDetails } from '~/services/generated/PurchaseClient';
import type { UpdateOrCreateLineItemProps } from './cart.api';
import {
  deleteCart,
  deleteCartLineItem,
  getCart,
  updateCartLineItem,
  updateOrCreateLineItem,
} from './cart.api';

export interface CartStore extends GlobalStore {
  isMiniCartOpen: boolean;
  cart?: ICart;
  actions: {
    fetch: () => Promise<void>;
    deleteCart: () => Promise<void>;
    replaceCart: (cart: ICart) => Promise<void>;
    updateCart: (props: UpdateOrCreateLineItemProps) => Promise<boolean>;
    removeLineItem: (lineItemId: string, checkoutCartId?: string) => Promise<void>;
    setIsMiniCartOpen: (val: boolean) => void;
    lineItemArticleCount: (lineItemId: string) => number | undefined;
    getMaxQuantity: (code: string) => number | undefined;
    getMappedLineItem: (code: string | number) => LineItem | undefined;
    updateLineItem: (
      props: { lineItemId: string; quantity: number },
      checkoutCartId?: string,
    ) => Promise<void>;
    getPrescriptionDetails: (code: string | number) => PrescriptionDetails | undefined;
  };
  articleCount: () => number | undefined;
}

export const useCartStore = create<CartStore>()(
  devtools<CartStore>(
    (set, get) => ({
      ...initialGlobalState,
      isMiniCartOpen: false,
      actions: {
        fetch: async () => {
          set({ loading: true, updated: Date.now(), error: false }, false, 'Fetching Cart');
          const { cart, errorMessage } = await getCart();
          if (cart) {
            set(
              {
                cart,
                updated: Date.now(),
                error: false,
                errorMessage: undefined,
                loading: false,
              },
              false,
              'Fetched Cart',
            );
          } else {
            set(
              {
                error: true,
                errorMessage,
                updated: Date.now(),
                loading: false,
              },
              false,
              'Failed',
            );
          }
        },
        replaceCart: async (cart: ICart) => {
          set({ cart, updated: Date.now() });
        },
        updateCart: async (props) => {
          set(() => ({ loading: true, updated: Date.now() }), false, 'Updating Cart');

          suspendDeliveryWidget();
          suspendPaymentWidget();

          const { cart, errorMessage } = await updateOrCreateLineItem(props);

          if (cart) {
            set(
              () => ({
                loading: false,
                cart,
                updated: Date.now(),
              }),
              false,
              'Updated Cart',
            );
          } else {
            set(
              () => ({
                loading: false,
                error: true,
                errorMessage,
                updated: Date.now(),
              }),
              false,
              'Failed',
            );
          }

          resumeDeliveryWidget();
          resumePaymentWidget();

          return cart != null;
        },
        deleteCart: async () => {
          const cartId = get()?.cart?.id;
          if (!cartId) {
            return;
          }
          set(() => ({ updated: Date.now(), loading: true }), false, 'Begin Deleting Cart');
          await deleteCart(cartId);

          const { cart, errorMessage } = await getCart();

          if (cart) {
            set(() => ({
              updated: Date.now(),
              loading: false,
              cart,
            }));
          } else {
            set(
              () => ({
                updated: Date.now(),
                loading: false,
                error: true,
                errorMessage,
              }),
              false,
              'Delete Failed',
            );
          }
        },

        removeLineItem: async (lineItemId, checkoutCartId?) => {
          const cartId = get()?.cart?.id || checkoutCartId;

          if (!cartId) {
            return;
          }
          set(
            () => ({
              loading: true,
              updated: Date.now(),
            }),
            false,
            'Removing LineItem',
          );
          const { cart, errorMessage } = await deleteCartLineItem({ cartId, lineItemId });

          if (cart) {
            set(
              () => ({
                loading: false,
                cart,
                updated: Date.now(),
              }),
              false,
              'Removed LineItem',
            );
          } else {
            set(
              () => ({
                loading: false,
                error: true,
                errorMessage,
                updated: Date.now(),
              }),
              false,
              'Failed',
            );
          }
        },
        updateLineItem: async ({ lineItemId, quantity }, checkoutCartId?) => {
          const cartId = get()?.cart?.id || checkoutCartId;

          if (!cartId) {
            return;
          }
          set(
            () => ({
              loading: true,
              updated: Date.now(),
            }),
            false,
            'Updating LineItem',
          );

          const { cart, errorMessage } = await updateCartLineItem(
            { cartId, lineItemId },
            { quantity },
          );

          if (cart) {
            set(
              () => ({
                updated: Date.now(),
                loading: false,
                error: false,
                cart,
              }),
              false,
              'Updated LineItem',
            );
          } else {
            set(
              () => ({
                loading: false,
                error: true,
                errorMessage,
                updated: Date.now(),
              }),
              false,
              'Failed',
            );
          }
        },
        setIsMiniCartOpen: (val: boolean) => {
          set(() => ({
            isMiniCartOpen: val,
          }));
        },
        lineItemArticleCount: (lineItemId: string) =>
          get()?.cart?.lineItems?.find((i) => i.id === lineItemId)?.quantity,

        getMaxQuantity: (code: string) =>
          get()?.cart?.lineItems?.find((i) => i.articleCode === code)?.maxQuantity,

        getMappedLineItem: (code: string | number) =>
          get()?.cart?.lineItems?.find((l) => l.articleCode === code.toString()),

        getPrescriptionDetails: (code: string | number) => {
          const lineItem = get().actions.getMappedLineItem(code);
          const prescriptionBagId = lineItem?.rxItemProperties?.prescriptionBagId;
          return get().cart?.prescriptionDetails?.find((x) => x.id === prescriptionBagId);
        },
      },
      articleCount: () =>
        get()?.cart?.lineItems?.reduce((count, lineItem) => count + (lineItem.quantity ?? 0), 0),
    }),
    {
      name: 'CartStore',
      anonymousActionType: `Cart`,
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useCartActions = () => useCartStore((state) => state.actions);
