export const formatPersonalNumber = (
  inputPnr: string | number | undefined | null,
): string | null => {
  if (!inputPnr) {
    return null;
  }
  // make sure pnr is a string.
  let pnr = `${inputPnr}`;
  // remove whitespaces and dashes
  pnr = pnr.replace(' ', '').replace('-', '');
  // pnr needs to be 12 chars long.
  if (pnr.length !== 12) {
    return null;
  }
  // return the age.
  return `${pnr.slice(0, 8)}-${pnr.slice(8)}`;
};
