import { SvgIcon } from '@mui/material';
import CheckFilled from '@aph/icons/check-filled.svg';
import Tick from '@aph/icons/tick.svg';
import type { IAphChecklistFields } from '~/contentful/types';

type AphChecklistIconProps = {
  iconVariant: IAphChecklistFields['iconVariant'];
};

export const AphChecklistIcon = ({ iconVariant }: AphChecklistIconProps) => {
  switch (iconVariant) {
    case 'Check':
      return (
        <SvgIcon viewBox="0 0 24 24" color="success" data-testid="APH.CHECKLIST.ICON.CHECK">
          <Tick aria-hidden="true" />
        </SvgIcon>
      );
    case 'Check-filled':
      return (
        <SvgIcon viewBox="0 0 24 24" color="success" data-testid="APH.CHECKLIST.ICON.CHECK-FILLED">
          <CheckFilled aria-hidden="true" />
        </SvgIcon>
      );
    default:
      return (
        <SvgIcon viewBox="0 0 24 24" color="success">
          <Tick aria-hidden="true" />
        </SvgIcon>
      );
  }
};
