// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFeatureToggleName = (featureName: string | undefined, path = '') => {
  if (featureName) {
    return featureName;
  }
  if (isFindPharmacyDetailPage(path)) {
    return 'aph_features_findpharmacy';
  }
  return '';
};

export { getFeatureToggleName };

function isFindPharmacyDetailPage(path: string) {
  return path.match(/\/hitta-apotek-hjartat\/.*\/.+\/$/);
}
