export interface ArticleImageSource {
  url?: string | undefined;
  publicId?: string | undefined;
}

interface CloudinaryImage extends ArticleImageSource {
  publicId: string;
}

export function isCloudinaryImage(src: ArticleImageSource | undefined): src is CloudinaryImage {
  return (src as CloudinaryImage).publicId !== undefined;
}
