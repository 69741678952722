/* eslint-disable react/destructuring-assignment */

/* eslint-disable prefer-arrow-callback */
import { forwardRef } from 'react';
import Head from 'next/head';
import { type UnpicSourceProps, transformSourceProps } from '@unpic/core';
import type { ImageProps } from '@unpic/react';
import { Image, Source } from '@unpic/react';
import { DevelopmentOnly } from '@aph/components/common/development-only/development-only.component';
import type { Omit } from '@aph/utilities/ts-omit';
import { getPropsForLayout } from '@aph/utilities/unpic-image';

type ContentfulImageOptionsFit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
type ContentfulImageOptionsQuality = number;
type ContentfulCdnParams = {
  fit?: ContentfulImageOptionsFit;
  quality?: ContentfulImageOptionsQuality;
};

type ContentfulImageProps = Omit<ImageProps, 'cdn' | 'background'> & ContentfulCdnParams;

export const ContentfulImage = forwardRef<HTMLImageElement, ContentfulImageProps>(
  function ContentfulImage({ fit, quality, className, alt, priority, unstyled, ...props }, ref) {
    const url = getContentfulImageSrcUrl(props.src, { fit, quality });

    if (url.host !== 'images.ctfassets.net') {
      return (
        <DevelopmentOnly>
          <div className="bg-notice-dark text-inverse rounded-md p-2">
            <b>{url.host}</b> is not a valid CDN url for Contentful images (maybe the asset too
            large, 20MB+)
          </div>
        </DevelopmentOnly>
      );
    }

    const universalProps = getPropsForLayout({
      ...props,
      src: url.toString(),
      cdn: 'contentful',
    });

    return (
      <>
        <picture className="contents">
          <Source {...universalProps} type="image/webp" />
          <Image
            {...universalProps}
            ref={ref}
            alt={alt}
            priority={priority}
            unstyled={unstyled}
            className={className}
          />
        </picture>
        {priority ? <ImagePreload {...universalProps} type="image/webp" /> : null}
      </>
    );
  },
);

const ImagePreload = (props: ImageProps & Pick<UnpicSourceProps, 'type'>) => {
  const imgAttributes = transformSourceProps(props);

  // this is the way next/image does it
  return (
    <Head>
      <link
        key={`__aph-img-${props.src}${imgAttributes.srcset}${imgAttributes.sizes}`}
        rel="preload"
        as="image"
        href={imgAttributes.srcset ? undefined : props.src}
        imageSrcSet={imgAttributes.srcset ?? undefined}
        imageSizes={imgAttributes.sizes ?? undefined}
        referrerPolicy={props.referrerPolicy}
        crossOrigin={props.crossOrigin}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - prefetchpriority does exists but we have invalid types
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority="high"
      />
    </Head>
  );
};

function getContentfulImageSrcUrl(src: string, options: ContentfulCdnParams) {
  const url = src.startsWith('//') ? new URL(`https:${src}`) : new URL(src);

  if (options.fit) {
    url.searchParams.set('fit', options.fit);
  }
  if (options.quality) {
    url.searchParams.set('q', options.quality.toString());
  }

  return url;
}
