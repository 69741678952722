import type { ArticleData, ArticleTrackingData } from '~/articles/tracking';
import { mapArticleToTrackingData } from '~/articles/tracking';
import { sendEcommerce } from '../useGtm';

export type CartTrackingEvent = {
  index?: number;
  quantity: number;
  is_sensitive?: 'yes' | 'no';
} & Omit<ArticleTrackingData, 'is_sensitive'>;

export type CartEvent = {
  listName?: ArticleTrackingData['item_list_name'];
  newQuantity: number;
  oldQuantity: number;
} & ArticleData &
  Pick<CartTrackingEvent, 'index'>;

const mapIsSensitive = (event: CartEvent) => {
  if (event.isSensitive === undefined) return undefined;

  return event.isSensitive ? 'yes' : 'no';
};

const mapToCartTrackingEvent = (event: CartEvent): CartTrackingEvent => ({
  ...mapArticleToTrackingData(event, event.listName),
  quantity: Math.abs(event.oldQuantity - event.newQuantity),
  is_sensitive: mapIsSensitive(event),
  index: event.index,
});

export const sendAddToCartEvent = (event: CartEvent) => {
  sendEcommerce({
    event: 'add_to_cart',
    ecommerce: {
      items: [mapToCartTrackingEvent(event)],
    },
  });
};

export const sendRemoveFromCartEvent = (event: CartEvent) => {
  sendEcommerce({
    event: 'remove_from_cart',
    ecommerce: {
      items: [mapToCartTrackingEvent(event)],
    },
  });
};
