import { logger } from '~/logging';

function authRedirectConfig(): Record<string, string> {
  if (!process.env.NEXT_PUBLIC_REDIRECT || process.env.NEXT_PUBLIC_REDIRECT.toString() === '') {
    logger.warn({}, 'No redirect config available, using default');
    return JSON.parse(
      '{"authRedirect":"/mina-recept/logga-in/","hasNoSessionRedirect":"/logga-ut/","isNotClubMemberRedirect":"/klubb-hjartat/bli-medlem/","clubMemberAuthRedirect":"/klubb-hjartat/mina-sidor/","start":"/"}',
    );
  }
  return JSON.parse(process.env.NEXT_PUBLIC_REDIRECT);
}

export { authRedirectConfig };
