import React from 'react';
import { Typography, type TypographyProps } from '@aph/ui/components/typography/typography';
import { formatPrice } from '@aph/utilities/format-price/format-price';

type Size = 'small' | 'medium' | 'large';

export type PriceFormatterProps = {
  price: number;
  strikeThrough?: boolean;
  size?: Size;
  label?: React.ReactNode;
  color?: TypographyProps['color'];
  unit?: React.ReactNode;
};

const priceSizeMapper: Record<Size, TypographyProps['typography']> = {
  small: 'priceSmall',
  medium: 'priceMedium',
  large: 'priceLarge',
};

export const PriceFormatter: React.FC<PriceFormatterProps> = ({
  price,
  strikeThrough,
  label,
  color = 'text-subtle',
  unit,
  size = 'large',
}) => (
  <div className="flex flex-col items-baseline">
    <Typography typography="subHeading" color="text-subtle">
      {label}
    </Typography>
    <Typography
      data-testid="PRICE_FORMATTER_COMPONENT"
      typography={priceSizeMapper[size]}
      className={strikeThrough ? 'line-through' : ''}
      color={color}
    >
      {formatPrice(price)}
      {unit}
    </Typography>
  </div>
);
