/* eslint-disable prefer-arrow-callback */
import { forwardRef } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { dpr, format, quality } from '@cloudinary/url-gen/actions/delivery';
import { limitFit } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/dpr';
import type { ImageProps } from '@unpic/react';
import { Image } from '@unpic/react';
import { Image as NextImage } from '@unpic/react/nextjs';
import MissingImageFallback from '@aph/images/no_image.png';
import RxImageFallback from '@aph/images/rx_image.png';
import { type ImagePreset, addImagePreset } from '@aph/utilities/image-presets';
import {
  type ArticleImageSource,
  isCloudinaryImage,
} from '~/articles/utilities/article-image/article-image';

type ArticleImageProps = Omit<ImageProps, 'src'> & {
  src: ArticleImageSource | undefined;
  shouldUseRxFallback?: boolean;
  preset?: ImagePreset;
};

const cld = new Cloudinary({
  cloud: { cloudName: 'icase-eu' },
  url: { secureDistribution: 'assets.icanet.se', privateCdn: true, analytics: false },
});

/**
 * Renders a product/article image.
 * If the image is missing, a fallback image is shown.
 * It supports cloudinary images and inRiver images.
 * It also supports Rx fallback images (for recipe products).
 */
export const ArticleImage = forwardRef<HTMLImageElement, ArticleImageProps>(function ArticleImage(
  { src, shouldUseRxFallback, preset, ...props },
  ref,
) {
  if (shouldUseRxFallback) {
    return (
      <NextImage
        ref={ref}
        width={MissingImageFallback.width}
        height={MissingImageFallback.height}
        {...props}
        src={RxImageFallback.src}
        alt="receptbelagd produkt"
      />
    );
  }
  if (!src?.url) {
    return (
      <NextImage
        ref={ref}
        width={MissingImageFallback.width}
        height={MissingImageFallback.height}
        {...props}
        src={MissingImageFallback.src}
        alt="bild saknas"
      />
    );
  }

  if (isCloudinaryImage(src)) {
    return (
      <Image
        ref={ref}
        {...(props as ImageProps)}
        src={src.publicId}
        transformer={(options) => {
          // ref: https://cloudinary.com/documentation/transformation_reference#overview
          // NOTE: this is currently for article images only since that's the only place we use cloudinary images (if that changes, we probably need to move this to a more generic place)
          return cld
            .image(options.url.toString())
            .resize(limitFit(options.width ?? props.width, options.height ?? props.height))
            .delivery(dpr(auto()))
            .delivery(format('auto'))
            .delivery(quality('auto'))
            .toURL();
        }}
      />
    );
  }

  return (
    <Image
      {...(props as ImageProps)}
      src={src.url}
      transformer={(options) => addImagePreset(options.url.toString(), preset || 'large')}
    />
  );
});

ArticleImage.displayName = 'ArticleImage';
