import { useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { type IArticle, ProductType } from '../../generated/ArticlesClient';

type ArticleProductTypeProps = Pick<IArticle, 'productType'>;

const RX_TYPES = [ProductType.Supplement, ProductType.Drug];

export const ArticleProductType = ({ productType }: ArticleProductTypeProps) => {
  const { formatMessage } = useIntl();

  if (!productType || !RX_TYPES.includes(productType)) {
    return null;
  }
  return (
    <Typography data-testid="ArticleProductType" typography="footnote" color="text-subtle">
      {formatMessage({ id: `PRODUCT_DETAILS.TYPE.${productType.toUpperCase()}` })}
    </Typography>
  );
};
