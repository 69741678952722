import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import type { MemberCheckResponseV3 } from '~/services/generated/CustomerClient';

export type AmIMemberState = {
  data: MemberCheckResponseV3 | null | undefined;
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null;
  loading: boolean;
  updated: number;
};

export const initialState: AmIMemberState = {
  data: undefined,
  error: null,
  loading: false,
  updated: 0,
};
