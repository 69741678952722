import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getLoginRedirectUrl } from '@aph/utilities/login/login.utils';
import {
  useBonusBalanceActions,
  useBonusBalanceStore,
} from '~/model/bonus/balance/use-bonus-balance-store';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';

export const useProfile = () => {
  const router = useRouter();
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const { bonusBalance } = useBonusBalanceStore();
  const { getBonusBalance } = useBonusBalanceActions();
  const { pathname } = router;

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
  }, [amIMember.updated, getAmIMember]);

  const isAuthenticated =
    amIMember.data && (amIMember.data.isMember === true || amIMember.data.isMember === false);

  useEffect(() => {
    if (amIMember.data?.isMember === true && bonusBalance.updated === 0) {
      getBonusBalance();
    }
  }, [bonusBalance, getBonusBalance, amIMember]);

  const loginHref =
    pathname === '/logga-ut'
      ? getLoginRedirectUrl('top_navigation', '/')
      : getLoginRedirectUrl('top_navigation');

  return useMemo(
    () => ({
      login: () => router.push(loginHref),
      isAuthenticated,
      isLoading: amIMember.loading || amIMember.updated === 0,
      bonusBalance,
    }),
    [amIMember.loading, amIMember.updated, bonusBalance, isAuthenticated, loginHref, router],
  );
};
