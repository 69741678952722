import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { type IArticle } from '../../generated/ArticlesClient';
import { sendSelectItemEvent, sendViewItemListEvent } from '../../tracking';

export const useArticleCardTracking = (article?: IArticle, listName?: string, index = 0) => {
  const { ref: cardRef, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && article && listName) {
      sendViewItemListEvent(article, listName, index + 1);
    }
  }, [inView, article, index, listName]);

  return {
    sendSelectItemEvent: () => {
      if (article && listName) {
        sendSelectItemEvent(article, listName, index);
      }
    },
    cardRef,
  };
};
