import React from 'react';
import { type RenderNode } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { cn } from '@aph/ui/tailwind/cn';
import { isRichText, renderRichText } from './render';

// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
interface RichTextComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  field: Document | undefined;
  overrideNodes?: RenderNode;
}

// styles for the rich text component is located in the global tailwind css file
export const RichTextComponent = ({
  field,
  overrideNodes,
  className,
  ...props
}: RichTextComponentProps) => (
  <div className={cn('rich-text--component', className)} {...props}>
    {isRichText(field) ? renderRichText(field, overrideNodes) : field}
  </div>
);
