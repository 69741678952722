import React from 'react';
import { Typography } from '@aph/ui/components/typography/typography';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';

type GenericSearchResultsProps = {
  results: Array<{ name: string | undefined; href: string }> | undefined;
};
export const GenericSearchResults: React.FC<GenericSearchResultsProps> = ({ results }) => {
  if (results?.length) {
    return (
      <ul className="flex flex-col gap-1.5">
        {results.map((result) => (
          <li key={result.href}>
            <Typography typography="link" asChild>
              <FeatureToggledNextLink rel="nofollow" href={result.href}>
                {result.name}
              </FeatureToggledNextLink>
            </Typography>
          </li>
        ))}
      </ul>
    );
  }

  return null;
};
