import type { AdhocVoucherV4, MonthVoucherV4 } from '~/services/generated/CustomerClient';
import { OpCoTypeV4 } from '~/services/generated/CustomerClient';

export const sumAphValidVouchers = (
  adhocVouchers?: AdhocVoucherV4[] | undefined,
  monthVouchers?: MonthVoucherV4[] | undefined,
) => {
  let sum = 0;
  if (adhocVouchers) {
    adhocVouchers.forEach((adHocVoucher) => {
      adHocVoucher.vouchers?.forEach((voucher) => {
        if (voucher.validAtOpCo === OpCoTypeV4.All || voucher.validAtOpCo === OpCoTypeV4.Aph) {
          sum += voucher.voucherAmount;
        }
      });
    });
  }

  if (monthVouchers) {
    monthVouchers.forEach((monthVoucher) => {
      monthVoucher.vouchers?.forEach((voucher) => {
        if (voucher.validAtOpCo === OpCoTypeV4.All || voucher.validAtOpCo === OpCoTypeV4.Aph) {
          sum += voucher.voucherAmount;
        }
      });
    });
  }

  return sum;
};
