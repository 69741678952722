import { useState } from 'react';
import type { IInfoBarFields } from '~/contentful/types';
import { api } from '~/utilities/trpc-api';

const GLOBAL_SERVICE_MESSAGE_SLUG = 'global-service-message';

export const useInfoBar = () => {
  const [hiddenInfoBars, setHiddenInfoBars] = useState<IInfoBarFields['slug'][]>([]);

  const { data: globalServiceMessage } = api.content.getInfoBar.useQuery(
    { slug: GLOBAL_SERVICE_MESSAGE_SLUG },
    {
      staleTime: 60000, // 1 minute
      retry: false,
    },
  );

  const isInfoBarHidden = (slug: IInfoBarFields['slug']) =>
    Boolean(slug && hiddenInfoBars.includes(slug));

  const hideInfoBar = (slug: IInfoBarFields['slug']) => {
    setHiddenInfoBars((state) => [...state, slug]);
  };

  return {
    hideInfoBar,
    isInfoBarHidden,
    globalServiceMessage,
  };
};
