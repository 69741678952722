/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Skeleton } from '@mui/material';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@aph/ui/components/collapsible/collapsible.compontent';
import { Icon } from '@aph/ui/components/icon/icon';
import { variants as typographyVariants } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { CustomVariantProps } from '@aph/ui/tailwind/variant-props';
import type { CategoryNavigationItem } from '~/articles/hooks/use-get-category-navigation';
import { useGetCategoryNavigation } from '~/articles/hooks/use-get-category-navigation';

type ArticleCategoryTreeProps = {
  category: CategoryNavigationItem;
  level?: number;
};

function handleNavigate(href: string) {
  sendMainNavigationEvent(parseEventTree(href));
}

export const ArticleCategoryTree: React.FC<ArticleCategoryTreeProps> = ({
  category,
  level = 1,
}) => {
  const isMatch = useMatchRoute();

  const isCollapsible = category.children.length > 0 || category.hasChildren;
  const isActive = isMatch(category.href);
  const isNotExactMatch = !isMatch(category.href, { exact: true });

  if (!isCollapsible) {
    return (
      <li>
        <ArticleCategoryMenuButton
          className={cn('no-underline', isActive && ['text-brand', 'font-bold'])}
          asChild
        >
          <Link href={category.href} onClick={() => handleNavigate(category.href)}>
            {category.title}
          </Link>
        </ArticleCategoryMenuButton>
      </li>
    );
  }

  return (
    <li>
      <Collapsible defaultOpen={isActive}>
        <CollapsibleTrigger className="group" asChild>
          <ArticleCategoryMenuButton level={level > 1 ? 'nested' : 'root'}>
            {category.title}
            <Icon
              name="ArrowheadRight"
              size="small"
              className="fill-subtlest group-hover:fill-subtle group-focus-visible:fill-subtle ml-2 mt-[1.5px] flex-shrink-0 self-start transition-transform duration-200 group-aria-expanded:rotate-90 group-aria-expanded:fill-current"
            />
          </ArticleCategoryMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent className="ml-1 mt-1">
          <ul className="flex w-full flex-col gap-y-1">
            {isNotExactMatch ? (
              <li>
                <ArticleCategoryMenuButton className={cn('no-underline')} asChild>
                  <Link
                    href={category.href}
                    aria-label={category.title}
                    onClick={() => handleNavigate(category.href)}
                  >
                    Visa alla
                  </Link>
                </ArticleCategoryMenuButton>
              </li>
            ) : null}

            {category.children.map((subCategory) => (
              <ArticleCategoryTree
                key={subCategory.href}
                category={subCategory}
                level={level + 1}
              />
            ))}
          </ul>
        </CollapsibleContent>
      </Collapsible>
      <a href={category.href} tabIndex={-1} aria-hidden className="sr-only">
        {category.title}
      </a>
    </li>
  );
};

export const ArticleCategoryNavigation = () => {
  const { error, data: categories = [] } = useGetCategoryNavigation();
  const router = useRouter();

  if (categories) {
    return (
      <ul className="flex w-full flex-col gap-y-1" key={router.asPath} aria-label="Kategorier">
        {categories.map((category) => (
          <ArticleCategoryTree key={category.href} category={category} />
        ))}
      </ul>
    );
  }

  if (error) {
    return null;
  }

  // loading state...
  return (
    <>
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
    </>
  );
};

const menuButtonVariants = cva(
  'hover:bg-default text-default flex w-full items-center justify-between overflow-hidden rounded-md px-1 py-0.5 text-left outline-none ring-offset-0 transition focus-visible:ring-1 aria-expanded:font-bold aria-expanded:ring-offset-2',
  {
    variants: {
      level: {
        root: [
          'aria-expanded:bg-action-dark',
          'aria-expanded:text-inverse-dark',
          'ring-action-dark',
          'aria-expanded:ring-action-darkest',
        ],
        nested: [
          'aria-expanded:bg-visual',
          'aria-expanded:text-brand',
          'ring-visual-darkest',
          'aria-expanded:ring-visual-darkest',
        ],
      },
    },
    defaultVariants: {
      level: 'root',
    },
  },
);

const ArticleCategoryMenuButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'> & {
    asChild?: boolean;
  } & CustomVariantProps<typeof menuButtonVariants>
>(({ asChild = false, level = 'root', className, children, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'button';

  return (
    <Component
      ref={forwardedRef}
      className={cn(
        typographyVariants({ typography: 'body' }),
        menuButtonVariants({ level }),
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
});
