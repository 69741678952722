import { BaseApiClient } from '@aph/utilities/BaseApiClient';
import type { GetArticlesParams } from '~/articles/api/ArticlesApiClient';
import { withFilterQuerySerializer } from '~/articles/api/with-filter-query-serializer';
import type { IFilter, ISearchResponse, ISorting } from '~/articles/generated/ArticlesClient';
import type { components, paths } from '~/articles/generated/api.schema';
import { logger } from '~/logging';

export type GetSearchResultParams = Omit<GetArticlesParams, 'byResourceType'> & {
  searchPhrase: string;
  source: components['schemas']['SearchSource'];
  type: components['schemas']['SearchType'];
};

export type GetSearchFilterAndSortingOptions = Pick<
  GetSearchResultParams,
  'source' | 'searchPhrase'
>;

export class SearchApiClient extends BaseApiClient<paths> {
  public async getSearchResult(params: GetSearchResultParams) {
    const { data, error, response } = await this.client.GET('/bff-articles/v{version}/search', {
      querySerializer: withFilterQuerySerializer,
      params: {
        query: {
          SearchPhrase: params.searchPhrase,
          SearchSource: params.source,
          SearchType: params.type,
          'Sorting.Selected': params.sortBy,
          Filter: params.filterBy,
          'Pagination.Take': params.take,
          'Pagination.Skip': params.skip,
        },
        path: {
          version: '1',
        },
      },
    });

    if (error) {
      logger.error({ ...error, url: response.url }, `getArticlesByCategory: ${error.title}.`);
      throw error;
    }

    return data as ISearchResponse;
  }

  public async getFilterAndSortingOptions({
    source,
    searchPhrase,
  }: GetSearchFilterAndSortingOptions) {
    return this.client
      .GET('/bff-articles/v{version}/search', {
        params: {
          query: {
            'Pagination.Take': 1,
            SearchSource: source,
            SearchPhrase: searchPhrase,
            SearchType: 'DetailedSearch',
          },
          path: {
            version: '1',
          },
        },
      })
      .then((res) => {
        if (res.data) {
          switch (source) {
            case 'Articles':
            default:
              return {
                // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
                sorting: res.data?.articles?.sorting as ISorting,
                // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
                filtering: res.data?.articles?.filter as IFilter,
              };
          }
        }

        return null;
      });
  }
}
