import React from 'react';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import type { FooterLinkType } from '../links/links-data';

export enum TextVariants {
  bodyBig = 'bodyBig',
  body = 'body',
}

interface LinkListProps extends React.HTMLAttributes<HTMLElement> {
  links: FooterLinkType[];
  onLinkClick?: (args: { link: FooterLinkType }) => void;
  variant?: TextVariants;
}

export const LinkList = ({
  links,
  variant = TextVariants.body,
  className,
  onLinkClick,
}: LinkListProps) => {
  const isActiveLink = useMatchRoute();

  return (
    <ul className={cn('flex flex-col gap-1', className)}>
      {links.map((link) => (
        <li key={link.href}>
          <Typography typography={variant} color="text-inverse" asChild>
            <FeatureToggledNextLink
              href={link.href}
              forceAnchorLink={link.isEpiLink}
              featureName={link.feature}
              className={isActiveLink(link.href, { exact: true }) ? 'active' : undefined}
              onClick={() => onLinkClick?.({ link })}
              underline="none"
              paddingY={0.5}
              paddingX={1}
              borderRadius={2}
              display="flex"
              paddingLeft={{ xs: 0, sm: 1 }}
              sx={{
                '&:hover': {
                  background: (theme) => theme.palette['color/background/action'],
                },
              }}
            >
              {link.title}
            </FeatureToggledNextLink>
          </Typography>
        </li>
      ))}
    </ul>
  );
};
