import { container } from 'tsyringe';
import { logger } from '~/logging';
import type { BalanceV4 } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const apiGetBonusBalance = async (correlationId: string): Promise<BalanceV4> => {
  const client = container.resolve(CustomerClient);

  const response = await client.getBalanceV4(correlationId, 'aphTinman', undefined);
  logger.info(`Correlation ID: ${correlationId} getBalanceV4() was called`);
  return response;
};
