const EVENT_DELIVERY_WIDGET_SUSPEND = 'EVENT_DELIVERY_WIDGET_SUSPEND';
const EVENT_DELIVERY_WIDGET_RESUME = 'EVENT_DELIVERY_WIDGET_RESUME';

let eventDeliveryWidgetSuspend: Event | null = null;
export const bindEventDeliveryWidgetSuspend = (): void => {
  eventDeliveryWidgetSuspend =
    eventDeliveryWidgetSuspend || (Event && new Event(EVENT_DELIVERY_WIDGET_SUSPEND));

  window.addEventListener(
    EVENT_DELIVERY_WIDGET_SUSPEND,
    () => {
      if (window && typeof window._sw === 'function') {
        window._sw(({ suspend }) => {
          if (suspend) {
            suspend();
          }
        });
      }
    },
    false,
  );
};
export const suspendDeliveryWidget = (): void => {
  if (eventDeliveryWidgetSuspend) {
    window.dispatchEvent(eventDeliveryWidgetSuspend);
  }
};

let eventDeliveryWidgetResume: Event | null = null;
export const bindEventDeliveryWidgetResume = (): void => {
  eventDeliveryWidgetResume =
    eventDeliveryWidgetResume || (!!Event && new Event(EVENT_DELIVERY_WIDGET_RESUME));

  window.addEventListener(
    EVENT_DELIVERY_WIDGET_RESUME,
    () => {
      if (window && typeof window._sw === 'function') {
        window._sw(({ resume }) => resume && resume());
      }
    },
    false,
  );
};
export const resumeDeliveryWidget = (): void => {
  if (eventDeliveryWidgetResume) {
    window.dispatchEvent(eventDeliveryWidgetResume);
  }
};
