import type { ImageProps } from '@unpic/react';

type FixedLayoutImageProps = Extract<ImageProps, { layout: 'fixed' }>;
type ConstrainedLayoutImageProps = Extract<ImageProps, { layout: 'constrained' }>;
type FullWidthLayoutImageProps = Extract<ImageProps, { layout: 'fullWidth' }>;

// HACK: we're using this as a trick to fool TS into thinking that the props are of a certain type (FixedLayoutImageProps, ConstrainedLayoutImageProps, FullWidthLayoutImageProps)
export const getPropsForLayout = (props: ImageProps) => {
  if (props.layout === 'fixed') {
    return props as FixedLayoutImageProps;
  }
  if (props.layout === 'fullWidth') {
    return props as FullWidthLayoutImageProps;
  }

  return props as ConstrainedLayoutImageProps;
};
