const EVENT_PAYMENT_WIDGET_SUSPEND = 'EVENT_PAYMENT_WIDGET_SUSPEND';
const EVENT_PAYMENT_WIDGET_RESUME = 'EVENT_PAYMENT_WIDGET_RESUME';

let eventPaymentWidgetSuspend: Event | null = null;
export const bindEventPaymentWidgetSuspend = (): void => {
  eventPaymentWidgetSuspend =
    eventPaymentWidgetSuspend || (Event && new Event(EVENT_PAYMENT_WIDGET_SUSPEND));

  window.addEventListener(
    EVENT_PAYMENT_WIDGET_SUSPEND,
    () => {
      if (window && typeof window._klarnaCheckout === 'function') {
        window._klarnaCheckout(({ suspend }) => {
          if (!suspend) {
            return;
          }
          suspend({
            autoResume: {
              enabled: false,
            },
          });
        });
      }
    },
    false,
  );
};
export const suspendPaymentWidget = (): void => {
  if (eventPaymentWidgetSuspend) {
    window.dispatchEvent(eventPaymentWidgetSuspend);
  }
};

let eventPaymentWidgetResume: Event | null = null;
export const bindEventPaymentWidgetResume = (): void => {
  eventPaymentWidgetResume =
    eventPaymentWidgetResume || (!!Event && new Event(EVENT_PAYMENT_WIDGET_RESUME));

  window.addEventListener(
    EVENT_PAYMENT_WIDGET_RESUME,
    () => {
      if (window && typeof window._klarnaCheckout === 'function') {
        window._klarnaCheckout(({ resume }) => {
          if (!resume) {
            return null;
          }
          return resume();
        });
      }
    },
    false,
  );
};
export const resumePaymentWidget = (): void => {
  if (eventPaymentWidgetResume) {
    window.dispatchEvent(eventPaymentWidgetResume);
  }
};
