import { type CostDetail, CostType } from '~/services/generated/PurchaseClient';

type GetPricePerUnitParams = {
  priceDetails: CostDetail[];
  quantity: number;
};

export const getPricePerUnit = ({ priceDetails, quantity }: GetPricePerUnitParams) => {
  const getPriceForCostType = (costType: CostType) => {
    const { value } = priceDetails?.find((i) => i.type === costType) || {};
    return value;
  };

  const calculatePrice = (price: number) => price / quantity;

  const totalPriceOfAllItems = getPriceForCostType(CostType.OriginalPrice);
  const totalPriceOfAllItemsWithDiscount = getPriceForCostType(CostType.DiscountPrice);

  return {
    originalPrice: totalPriceOfAllItems ? calculatePrice(totalPriceOfAllItems) : 0,
    discountedPrice:
      totalPriceOfAllItemsWithDiscount && totalPriceOfAllItemsWithDiscount !== totalPriceOfAllItems
        ? calculatePrice(totalPriceOfAllItemsWithDiscount)
        : undefined,
  };
};
