import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import { getErrorOrDefault } from '../utils';
import { type AmIMemberState, initialState } from './am-i-member-state';
import { amIMember } from './am-i-member.api';

interface AmIMemberStore {
  amIMember: AmIMemberState;
  actions: {
    getAmIMember: () => void;
  };
}

export const useAmIMemberStore = create<AmIMemberStore>()(
  devtools<AmIMemberStore>(
    (set) => ({
      amIMember: initialState,
      actions: {
        getAmIMember: async () => {
          const correlationId = uuidv4();
          set(
            {
              amIMember: {
                ...initialState,
                loading: true,
                error: null,
                updated: Date.now(),
              },
            },
            false,
            'Fetching amIMember',
          );
          try {
            const response = await amIMember(correlationId);
            logger.info(`getAmIMember, Correlation ID: ${correlationId} was fetched successfully`);
            set(
              {
                amIMember: {
                  data: response || initialState.data,
                  loading: false,
                  error: null,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching amIMember success',
            );
          } catch (event) {
            const { status, httpStatusCode } = event as {
              status: string;
              httpStatusCode: number;
            };
            const error = getErrorOrDefault(event, 'AM_I_MEMBER.FAILED_TO_FETCH');

            logger.error(
              `getAmIMember, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${
                error.errorMessage
              } | error: ${JSON.stringify(
                error,
              )} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                amIMember: {
                  data: null,
                  loading: false,
                  error: error || initialState.error,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching amIMember failed',
            );
          }
        },
      },
    }),
    {
      name: 'UseAmIMember',
      anonymousActionType: 'UseAmIMember',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useAmIMemberActions = () => useAmIMemberStore((state) => state.actions);
