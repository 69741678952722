import { FormattedMessage } from 'react-intl';
import { CloudinaryImage } from '@aph/components/cloudinary-image/cloudinary-image';
import KlarnaLogo from '@aph/images/klarna_logo.svg';
import SwishLogo from '@aph/images/swish_logo.svg';
import { Typography } from '@aph/ui/components/typography/typography';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { footerLegalLinks } from '../links/links-data';

export const PartnersAndLegal = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <>
      <div className="my-2 flex flex-col justify-between gap-1 sm:flex-row">
        <div className="flex items-center gap-1 self-start sm:self-center">
          <span role="img" aria-label="Klarna, logotyp" className="h-4">
            <KlarnaLogo />
          </span>
          <span
            role="img"
            aria-label="Swish, logotyp"
            className="bg-elevated flex h-4 items-center rounded-md"
          >
            <SwishLogo />
          </span>
        </div>
        <div className="mt-2 flex gap-1 pr-4 sm:mt-0 sm:gap-12">
          <a
            className="w-15 h-6 shrink-0 sm:mr-4"
            href="https://www.lakemedelsverket.se/sv/handel-med-lakemedel/apotek"
            target="_blank"
            rel="noreferrer"
          >
            <CloudinaryImage
              publicId="apotek_legal"
              objectFit="contain"
              width={250}
              aspectRatio={5 / 2}
              layout="constrained"
              alt=""
            />
            <span className="sr-only">Läs mer om apotek med tillstånd hos Läkemedelsverket</span>
          </a>
          <div className="flex shrink-0 gap-1 sm:gap-2">
            <a
              className="flex h-6"
              href="https://www.lakemedelsverket.se/sv/handel-med-lakemedel/foretag-som-saljer-lakemedel-via-e-handel/sok-foretag-med-e-handel/e-handlare/a00001/apotek-hjartat-ab"
              target="_blank"
              rel="noreferrer"
            >
              <CloudinaryImage
                publicId="pharmacy_eu_logo_animal"
                aspectRatio={6 / 5}
                layout="constrained"
                objectFit="contain"
                width={200}
                alt=""
              />
              <span className="sr-only">
                Klicka här för att kontrollera om webbplatsen är laglig
              </span>
            </a>
            <a
              className="flex h-6"
              href="https://www.lakemedelsverket.se/sv/handel-med-lakemedel/foretag-som-saljer-lakemedel-via-e-handel/sok-foretag-med-e-handel/e-handlare/a00001/apotek-hjartat-ab"
              target="_blank"
              rel="noreferrer"
            >
              <CloudinaryImage
                publicId="pharmacy_eu_logo"
                aspectRatio={6 / 5}
                layout="constrained"
                objectFit="contain"
                width={200}
                alt=""
              />
              <span className="sr-only">
                Klicka här för att kontrollera om webbplatsen är laglig
              </span>
            </a>
          </div>
        </div>
      </div>
      <hr className="border-default absolute h-[1px] w-[calc(100%-32px)] sm:left-0 sm:right-0 sm:w-full" />
      <div className="flex h-fit w-full flex-col items-center justify-between py-3 sm:flex-row sm:py-2">
        <Typography
          className="order-2 mt-4 w-full sm:order-1 sm:mt-0 sm:w-fit"
          typography="subHeading"
          color="text-inverse"
          asChild
        >
          <span>
            <FormattedMessage
              id="COMMON.FOOTER.LEGAL.COPYRIGHT_TEXT"
              values={{ currentYear: getCurrentYear() }}
            />
          </span>
        </Typography>
        <div className="order-1 flex w-full flex-col gap-2 sm:order-2 sm:w-fit sm:flex-row sm:gap-5">
          {footerLegalLinks.map((link) => (
            <Typography key={link.href} typography="subHeading" color="text-inverse" asChild>
              <FeatureToggledNextLink
                href={link.href}
                style={{ textDecoration: 'none' }}
                forceAnchorLink={link.isEpiLink}
              >
                {link.title}
              </FeatureToggledNextLink>
            </Typography>
          ))}
        </div>
      </div>
    </>
  );
};
